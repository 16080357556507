<nav
    *ngIf="menuEntry$ | async as menu"
    (mouseleave)="onMouseLeave()"
    (keydown.arrowleft)="focusLeft()"
    (keydown.arrowright)="focusRight()"
    (keydown.home)="setFocusToFistItem()"
    (keydown.end)="setFocusToLastItem()">
    <ul role="menubar" class="menu-bar-container">
        <ng-container *ngFor="let subMenuEntry of menu.menuEntryList; let index = index">
            <li *ngIf="subMenuEntry.type !== menuEntryType.separatorMenuEntry; else menuSeparator" role="none">
                <h-menu-parent-category
                    [menuEntry]="subMenuEntry"
                    [focused]="parentCategoryFocusedIndex() === index"
                    [onCursorLeaveMenuBar$]="mousLeaveEventsSubject"></h-menu-parent-category>
            </li>
        </ng-container>
    </ul>
</nav>

<ng-template #menuSeparator><div class="menu-separator"></div></ng-template>

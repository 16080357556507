<div [class.set-min-height]="setMinHeight">
    <div class="generic-input-item">
        <input
            #input
            [id]="id"
            [name]="name"
            [formControl]="control"
            [type]="inputType"
            [autocomplete]="autocomplete"
            [attr.spellcheck]="spellcheck"
            [class.generic-input-error]="controlHaveErrors()"
            [class.keyboard-navigation]="layoutFacade.keyboardNavigation$ | async"
            [class.large-left-input]="hasLeftIcon"
            [class.large-right-input]="hasRightIcon"
            [class.uppercase]="isUpperCase"
            [required]="isRequired()"
            [attr.role]="role"
            [attr.data-cs-mask]="contentSquareMaskEnabled ? '' : undefined"
            [attr.aria-invalid]="controlHaveErrors()"
            [attr.aria-describedby]="ariaDescribedby | ariaDescribedbyPipe : id"
            [attr.aria-autocomplete]="ariaAutoComplete"
            [attr.aria-controls]="ariaControls"
            [attr.aria-activedescendant]="ariaActiveDescendant"
            [attr.data-testid]="dataTestId"
            [attr.aria-disabled]="isDisabled"
            [attr.aria-readonly]="isDisabled"
            [mask]="mask"
            [dropSpecialCharacters]="maskDropSpecialCharacters"
            [specialCharacters]="$any(maskSpecialCharacters)"
            [patterns]="$any(maskPattern)"
            [validation]="false"
            [class.disabled]="isDisabled"
            [readOnly]="isDisabled"
            [placeholder]="!isDisabled && placeholder ? placeholder : '&nbsp;'"
            class="generic-input"
            title="{{ placeholder }}"
            maxlength="{{ maxLength }}"
            minlength="{{ minLength }}"
            (blur)="onBlur()"
            (keydown.enter)="forceBlur($event)"
            (touchstart)="onTouchStart()" />
        <label [class.large-left-label]="hasLeftIcon" class="generic-label label-width-{{ labelWidth }}" [for]="id"
            >{{ label }}
            <!-- '*' is an universal identifier for required input fields, no translation is used then -->
            <span *ngIf="isRequired()" [attr.aria-hidden]="'true'">{{ " *" }}</span>
            <span id="input-describe" hSrOnlyNoSnippet *ngIf="describe">{{ describe }}</span>
        </label>
        <span *ngIf="hasSuffix" class="suffix"
            >{{ control.value.length }} /
            {{ maxLength }}
            <span hSrOnlyNoSnippet aria-live="polite" i18n-aria-live>
                {{ control.value.length }} {{ "hermes.aftersales.trackingbar.of" | translate }} {{ maxLength }}
            </span>
        </span>
    </div>

    <div [id]="'input-error-' + id">
        <div #content>
            <!-- Required message -->
            <h-message-block
                *ngIf="errorManagement && controlHaveErrors() && control.errors?.required"
                [showBackground]="false"
                [autoAddPadding]="false"
                [ariaRole]="undefined"
                type="error">
                <ng-container i18n="@@hermes.common.required_information_input"> Required information </ng-container>
            </h-message-block>
            <ng-content></ng-content>
        </div>
        <span *ngIf="content.children.length !== 0" hSrOnlyNoSnippet>{{ label }}</span>
    </div>

    <ng-content select="[extra-information]"></ng-content>
</div>

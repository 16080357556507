import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import {
    filter,
    firstValueFrom,
    map,
    Observable,
    skip,
    Subscription,
    take,
    tap,
} from "rxjs";

import { CtaSize, LARGE } from "@hermes/aphrodite/call-to-action";
import { Product } from "@hermes/api-model-product";
import { Context, COUNTRY } from "@hermes/app-core";

import { Country } from "@hermes/locale";
import {
    ApplePayFacade,
    ApplePaySessionService,
    ApplepayStatus,
} from "@hermes/states/apple-pay";
import { BasketFacade } from "@hermes/states/basket";
import { ModalFacade, ModalType, ModalData } from "@hermes/states/modal";
import { ProcessingChannel } from "@hermes/states/payment-methods";
import { AnalyticsService } from "@hermes/utils/analytics";
import { BreakpointService } from "@hermes/utils-generic/services/user-interface";

import { AddToCartApplePayEvent } from "../../events/add-to-cart-apple-pay.event";
import {
    APPLE_PAY_BUTTON,
    APPLE_PAY_TERMS_CONDITIONS,
    ApplePayEvent,
} from "../../events/apple-pay.event";
import { ApplePayTermsAndConditionsComponent } from "../apple-pay-terms-and-conditions/apple-pay-terms-and-conditions.component";

@Component({
    selector: "h-call-to-action-apple-pay",
    templateUrl: "./call-to-action-apple-pay.component.html",
    styleUrls: ["./call-to-action-apple-pay.component.scss"],
})
export class CallToActionApplePayComponent implements OnInit, OnDestroy {
    @Input()
    public positions = [0];

    @Input()
    public displayElement: {
        applepayButton: boolean;
        termsAndConditions: boolean;
    } = {
        applepayButton: true,
        termsAndConditions: true,
    };

    @Input()
    public product?: Product = undefined;

    @Input()
    public isProductEngraving: boolean = false;

    @Input()
    public isDisabled: boolean = false;

    @Input()
    public size: CtaSize = LARGE;

    @Input({ required: true })
    public processingChannel!: ProcessingChannel;

    @ViewChild("termsAndConditions", { static: true })
    public termsAndConditionsComponent:
        | TemplateRef<ApplePayTermsAndConditionsComponent>
        | undefined;

    public status = ApplepayStatus;
    private subscriptions: Subscription = new Subscription();
    private skus: string[] = [];
    private isMobile: boolean = false;

    constructor(
        public applePayFacade: ApplePayFacade,
        private basketFacade: BasketFacade,
        private analyticsService: AnalyticsService,
        private modalFacade: ModalFacade,
        private context: Context,
        private applePaySessionService: ApplePaySessionService,
        private breakpointService: BreakpointService,
        @Inject(COUNTRY) public country: Country,
    ) {}

    public ngOnInit(): void {
        if (this.context.isInServerMode()) {
            return;
        }

        this.setSkus();

        this.sendApplepayEventToAnalytics(
            new ApplePayEvent({
                active: true,
                eventType: APPLE_PAY_BUTTON,
                sku: this.skus,
            }),
        );

        this.subscriptions.add(
            this.breakpointService
                .mediumBreakpointObserver()
                .pipe(map((value) => !value.matches))
                .subscribe((isMobile) => (this.isMobile = isMobile)),
        );

        this.subscriptions.add(
            this.applePaySessionService.token$
                .pipe(
                    filter(
                        (applePayData) =>
                            !!applePayData &&
                            this.displayElement.applepayButton,
                    ),
                    tap((applePayData) => {
                        if (applePayData.shippingContact) {
                            this.openModal({
                                type: ModalType.ApplePay,
                                token: applePayData.token,
                                shippingContact: applePayData.shippingContact,
                                processingChannel: this.processingChannel,
                            });
                        }
                    }),
                )
                .subscribe(),
        );

        this.subscriptions.add(
            this.applePayFacade.status$.subscribe((status) => {
                switch (status) {
                    case ApplepayStatus.AddingProductToCart: {
                        this.sendingAnalyticsOnClick();
                        break;
                    }
                    case ApplepayStatus.TermValidating: {
                        if (this.needToOpenModal()) {
                            this.openModal({
                                type: ModalType.TermsAndConditions,
                                templateRef: this.termsAndConditionsComponent,
                            });
                        }
                        break;
                    }
                    case ApplepayStatus.Buying: {
                        this.modalFacade.closeModal();
                        this.sendApplePayEventOnTermsClicked();
                        firstValueFrom(
                            this.applePaySessionService.doApplePayWorkflow(),
                        ).finally(() => this.applePayFacade.done());

                        break;
                    }
                    case ApplepayStatus.Ready: {
                        this.modalFacade.closeModal();
                        break;
                    }
                }
            }),
        );
    }

    public ngOnDestroy(): void {
        this.applePayFacade.abort();
        this.subscriptions.unsubscribe();
    }

    public needToOpenModal(): boolean {
        return this.displayElement.termsAndConditions && this.isMobile;
    }

    private openModal(modalData: ModalData): void {
        this.modalFacade.openModal(modalData);
        this.subscriptions.add(
            this.modalFacade.modalClosed$
                .pipe(
                    skip(1),
                    take(1),
                    filter((success) => !success),
                )
                .subscribe(() => {
                    this.applePayFacade.abort();
                }),
        );
    }

    private setSkus(): void {
        if (this.product) {
            this.skus = [this.product.sku];
        } else {
            this.subscriptions.add(
                this.getSkusFromBasketFacade().subscribe(
                    (skus) => (this.skus = skus),
                ),
            );
        }
    }

    private getSkusFromBasketFacade(): Observable<string[]> {
        return this.basketFacade.cart$.pipe(
            filter((cart) => !!cart?.items?.length),
            take(1),
            map((serializedBasket) =>
                this.basketFacade
                    .deserializeBasket(serializedBasket)
                    ?.items.map((item) => item.sku),
            ),
        );
    }

    private sendApplePayEventOnTermsClicked(): void {
        const eventOnTermsClicked = new ApplePayEvent({
            eventType: APPLE_PAY_TERMS_CONDITIONS,
            sku: this.skus.join("|"),
        });
        this.sendApplepayEventToAnalytics(eventOnTermsClicked);
    }

    private sendingAnalyticsOnClick(): void {
        const event = this.product
            ? new AddToCartApplePayEvent({
                  currencyCode: this.country.currency,
                  product: this.product,
                  isProductEngraving: this.isProductEngraving,
              })
            : new ApplePayEvent({
                  eventAction: "Checkout",
                  eventType: APPLE_PAY_BUTTON,
                  sku: this.skus,
              });

        this.sendApplepayEventToAnalytics(event);
    }

    private sendApplepayEventToAnalytics(
        event: ApplePayEvent | AddToCartApplePayEvent,
    ): void {
        this.analyticsService.sendData(event);
    }
}

import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from "@angular/core";
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    FormControlDirective,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { IConfig } from "ngx-mask";
import { scrollIntoView } from "seamless-scroll-polyfill";

import { LayoutFacade } from "@hermes/aphrodite/layout";
import { Context } from "@hermes/app-core";

import { ViewportService } from "@hermes/utils-generic/services/viewport";

import { BaseInputComponent } from "../base-input/base-input.component";

/**
 * This component implements a global Input Component. It also implements a floating label (like a mat-label - Material Angular).
 *
 * It uses the Reactive Forms to manage controls and data and implements the ControlValueAccessor interface.
 *
 * @see https://angular.io/guide/reactive-forms
 * @see https://angular.io/guide/forms-overview#common-form-foundation-classes
 *
 * You must use this input with a FormGroup : `controlName` must have the name of the AbstractControl.
 *
 * It manages basic forms errors for you :
 * * Required error
 * You can desactivate these automatic errors if you wish.
 *
 * You can also add errors message yourself (see example).
 *
 * Usage:
 * ```
 *  <h-input
 *      [id]="'field-id'"
 *      [name]="'field'"
 *      [inputType]="'email'"
 *      [label]="'My label translated into english'"
 *      [placeholder]="'My placeholder translated into english'"
 *      [describe]="'My describe'"
 *      [ariaDescribedby]="id-label"
 *      [hasLeftIcon]="false"
 *      [hasRightIcon]="false"
 *      [contentSquareMaskEnabled]="true"
 *      [autocomplete]="'username'"
 *      [ariaAutoComplete]="inline"
 *      [errorManagement]="true"
 *      [maskOptions]="maskOption"
 *      [controlName]="(field)"
 *      [setMinHeight]=false
 *      i18n-label="@@my.key.translate"
 *      i18n-placeholder="@@my.key.translate"
 *      i18n-describe="@@my.key.translate">
 *
 *      <h-message-block *ngIf="serviceError" type='error'>
 *             <ng-container i18n="@@my.key.translate">
 *                    New service error
 *             </ng-container>
 *      </h-message-block>
 *  </h-input>
 * ```
 *
 */

export const MEDIUM = "medium";
export const LARGE = "large";
export const FULL = "full";
export type LabelWidth = typeof MEDIUM | typeof LARGE | typeof FULL;

@Component({
    selector: "h-input",
    templateUrl: "./input.component.html",
    styleUrls: ["./input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
})
export class InputComponent
    extends BaseInputComponent
    implements ControlValueAccessor, Validator, OnDestroy
{
    /**
     * Direct reference to the "type" attribut of the input. Default is "text".
     *
     * Currently supported : "text" / "email" / "tel" / "password" / "number".
     *
     */
    @Input()
    public inputType: string = "text";

    /**
     * Set the mask - see how to use ngx-mask package : https://github.com/JsDaddy/ngx-mask / https://jsdaddy.github.io/ngx-mask/#7
     */
    @Input()
    public mask: string = "";
    /**
     * Set to define specific pattern - see how to use ngx-mask package : https://github.com/JsDaddy/ngx-mask
     */
    @Input()
    public maskPattern?: IConfig["patterns"];
    /**
     * Set the special characters you want to allow in input
     */
    @Input()
    public maskSpecialCharacters?: string[];
    /**
     * Set to false to get special characters in control value
     */
    @Input()
    public maskDropSpecialCharacters: boolean = true;

    /**
     * Direct reference to the "role" attribut of the input. Default is "undefined".
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles
     */
    @Input()
    public role: string | undefined = undefined;

    /**
     * Add a custom validation function (using the Validator interface).
     */
    @Input()
    public customValidatorFunction: (
        control: AbstractControl,
    ) => ValidationErrors | null = Validators.nullValidator;

    /**
     * If an icon is added on the left, we have the possibility to increase the padding on the left.
     */
    @Input()
    public hasLeftIcon: boolean = false;

    /**
     * If an icon is added on the right, we have the possibility to increase the padding on the right.
     */
    @Input()
    public hasRightIcon: boolean = false;

    @Input()
    public dataTestId?: string;

    // When true, sets a min height to the component in order for the error messages to display without moving other elements
    @Input()
    public setMinHeight: boolean = false;

    @Input()
    public labelWidth: LabelWidth = LARGE;

    @Input()
    public maxLength?: string;

    @Input()
    public minLength?: string;

    @Input()
    public isUpperCase?: boolean = false;

    @Input()
    public hasSuffix?: boolean = false;

    @Input()
    public isDisabled: boolean = false;

    @Output()
    public eventOnBlur: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild("input")
    public input!: ElementRef<HTMLInputElement>;

    /**
     * Observe FormControl usage
     */
    @ViewChild(FormControlDirective, { static: true })
    private formControlDirective!: FormControlDirective;

    constructor(
        protected override controlContainer: ControlContainer,
        public layoutFacade: LayoutFacade,
        private context: Context,
        private viewportService: ViewportService,
    ) {
        super(controlContainer);
    }

    public registerOnTouched(onTouchedFunction: void): void {
        this.formControlDirective.valueAccessor?.registerOnTouched(
            onTouchedFunction,
        );
    }

    public registerOnChange(onChangeFunction: void): void {
        this.formControlDirective.valueAccessor?.registerOnChange(
            onChangeFunction,
        );
    }

    public writeValue(object: string | number): void {
        this.formControlDirective.valueAccessor?.writeValue(object);
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        return this.customValidatorFunction(control);
    }

    public override focusInput(): void {
        this.input.nativeElement.focus();
        if (this.context.isInBrowserMode()) {
            requestAnimationFrame(() => {
                scrollIntoView(this.input.nativeElement, {
                    block: "center",
                    behavior: "smooth",
                });
            });
        }
    }

    /**
     * In case the Form is configured to update on blur events,
     * force the blur event to update value and validity of the AbstractControl
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event
     */
    public forceBlur(event: Event): void {
        const eventKeyboard = event as KeyboardEvent;
        // In case the user keeps pressing the key, the event is cancelled.
        if (eventKeyboard.repeat) {
            event.preventDefault();
            return;
        }

        if (this.control.updateOn === "blur") {
            const focusEvent = new FocusEvent("blur");
            this.input.nativeElement.dispatchEvent(focusEvent);
        }
    }
    public onTouchStart(): void {
        this.viewportService.setMaxScale(1);
    }

    public onBlur() {
        this.viewportService.removeMaxScale();
        this.eventOnBlur.emit();
    }

    public ngOnDestroy(): void {
        this.viewportService.removeMaxScale();
    }
}

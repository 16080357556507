<header #headerAndBannerContainer class="header-container" [attr.role]="'banner'">
    <div #bannerContainer>
        <ngx-loading-bar [color]="'#444444'" [height]="'5px'" [includeSpinner]="false" [fixed]="true"></ngx-loading-bar>
        <h-banners-top-container></h-banners-top-container>
        <h-seo-links *ngIf="menuFacade.links$ | async as seoLinks" [seoLinks]="seoLinks"></h-seo-links>

        <section
            #headerLinksContainer
            class="header-links-container"
            [class.transparent]="(isHeaderTransparent$ | async) === true">
            <h-header-links [isMenuHeaderActivated]="isMenuHeaderActivated()"></h-header-links>
        </section>
    </div>
    <!-- Background equal to scrolling part (fixed) -- needed to have a smooth scrolling when header goes from relative to fixed-->
    <div
        #headerBackgroundFixed
        class="header-background-fixed"
        [hidden]="headerState().headerPosition !== 'fixed'"></div>
    <!-- Header : scrolling part -->
    <div
        #headerMainContainer
        class="header-main-container"
        [class.fixed]="headerState().headerPosition === 'fixed'"
        [class.transparent]="(isHeaderTransparent$ | async) === true"
        [class.menu-header-activated]="isMenuHeaderActivated()">
        <div
            #headerMain
            class="header-main"
            [class.menu-header-activated]="isMenuHeaderActivated()"
            [class.hide-header-bar]="isMenuHeaderActivated() && headerState().isOnlyMenuBarDisplayed">
            <h-header-bar class="header-bar" [isMenuHeaderActivated]="isMenuHeaderActivated()"></h-header-bar>
        </div>
        <div #menuBar class="header-menu-bar">
            <h-menu-bar *ngIf="isMenuHeaderActivated()" [hidden]="hideMenuBarOnSSR$ | async"></h-menu-bar>
        </div>
    </div>
    <h-account-creation-banner
        *ngIf="isAccountCreated"
        [isAccountCreatedByWeChat]="isAccountCreatedByWechat"
        (closeBanner)="closeAccountCreationBanner()">
    </h-account-creation-banner>
</header>

import { Product } from "@hermes/api-model-product";
import {
    BK_ANALYTICS_PRODUCT_TYPE,
    GTMEcommerceItemData,
    GTMPageViewBase,
    PageViewAnalyticsEvent,
    PageViewAnalyticsEventData,
    HERMES_BRAND,
    NODATA,
    NO_SIZE,
    PRODUCT_IN_STOCK,
    PRODUCT_OUT_OF_STOCK,
    AvailabilityHitType,
    getAvailabilityHit,
    GTMBooleanType,
    NO,
    YES,
    isProductEngravable,
    isGroupingAvailable,
} from "@hermes/utils/analytics";
import { ProductTemplateType } from "@hermes/utils/constants";
import {
    isOnlineAndRetailStock,
    isRetailOnlyStock,
} from "@hermes/utils/helpers";

import {
    getPageType,
    getFormatedSku,
} from "../helpers/product-page-analytics.helpers";

export interface GTMPageViewProductPage extends GTMPageViewBase {
    pagetype: string;
    pageTranslate: string;
    productname: string;
    productsku: string;
    departmentcode: string;
    productlocator: GTMBooleanType;
    /* eslint-disable @typescript-eslint/naming-convention */
    in_store_only: string;
    personnalize_hit: GTMBooleanType;
    menu_lv1?: string;
    menu_lv2?: string;
    menu_lv3?: string;
    availability_hit: AvailabilityHitType;
    Stock_pageview_level: typeof PRODUCT_IN_STOCK | typeof PRODUCT_OUT_OF_STOCK;
    VTO_available: GTMBooleanType;
    grouping_available: GTMBooleanType;
    /* eslint-enable @typescript-eslint/naming-convention */
    ecommerce?: {
        detail: {
            products: GTMEcommerceItemData[];
        };
    };
}

export interface PageViewProductPageData extends PageViewAnalyticsEventData {
    product: Product;
    menuLvl: string | string[];
}

/**
 * This class is used for click on back button component
 */
export class PageViewProductPage extends PageViewAnalyticsEvent {
    public override eventData: PageViewProductPageData;

    constructor(data: PageViewProductPageData) {
        super(data);
        this.eventData = data;
    }
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction key
     */
    public override formatForGTM(): GTMPageViewProductPage {
        const product: Product = this.eventData.product;
        const menuLvl = this.eventData.menuLvl;

        /* eslint-disable @typescript-eslint/naming-convention */
        return {
            ...super.formatForGTM(),
            pagetype: getPageType(product.templateType),
            productname: product.title ?? "",
            productsku: getFormatedSku(product),
            departmentcode: this.getDepartementCode(product),
            productlocator:
                product.stock &&
                (isOnlineAndRetailStock(product.stock) ||
                    isRetailOnlyStock(product.stock))
                    ? YES
                    : NO,
            in_store_only: this.getInStoreOnly(product),
            personnalize_hit:
                isProductEngravable(product) || product.personalize ? YES : NO,
            menu_lv1: menuLvl?.[0],
            menu_lv2: menuLvl?.[1],
            menu_lv3: menuLvl?.[2],
            availability_hit: getAvailabilityHit(product),
            Stock_pageview_level: this.getStockPageviewLevel(product),
            VTO_available:
                product.isVto || product.vtoType !== undefined ? YES : NO,
            grouping_available: isGroupingAvailable(product) ? YES : NO,
            ...this.getProductDetails(product),
        };
        /* eslint-enable @typescript-eslint/naming-convention */
    }

    /**
     * Get all attributes depending on the product type.
     */
    public getFamilyAttributes(
        product: Product,
    ): Partial<GTMEcommerceItemData> | undefined {
        switch (product.templateType) {
            case ProductTemplateType.Simple: {
                return {
                    id: getFormatedSku(product),
                    category: `${product.departmentCode}/${product.familyCode}`,
                    variant: product.simpleAttributes?.colorHermes || NODATA,
                    // eslint-disable-next-line unicorn/explicit-length-check
                    dimension5: product.size || NODATA,
                    dimension6: product.departmentCode,
                    dimension7: product.familyCode,
                    dimension8: product.productCode,
                    dimension9: product.divisionCode,
                };
            }
            case ProductTemplateType.Beltkit: {
                return {
                    id: getFormatedSku(product),
                    category: `${product.beltkitAttributes?.departmentCodeLeather}|${product.beltkitAttributes?.departmentCodeBuckle}/${product.beltkitAttributes?.familyCodeLeather}|${product.beltkitAttributes?.familyCodeBuckle}`,
                    variant: `${product.beltkitAttributes?.colorHermesLeather}|${product.beltkitAttributes?.displayNameList} ${product.beltkitAttributes?.colorHermesBuckle}`,
                    dimension5: `${product.beltkitAttributes?.skuLeather.slice(
                        -3,
                    )}`,
                    dimension6: `${product.beltkitAttributes?.departmentCodeLeather}|${product.beltkitAttributes?.departmentCodeBuckle}`,
                    dimension7: `${product.beltkitAttributes?.familyCodeLeather}|${product.beltkitAttributes?.familyCodeBuckle}`,
                    dimension8: `${product.beltkitAttributes?.productCodeLeather}|${product.beltkitAttributes?.productCodeBuckle}`,
                    dimension9: `${product.beltkitAttributes?.divisionCodeLeather}|${product.beltkitAttributes?.divisionCodeBuckle}`,
                };
            }
            case ProductTemplateType.AppleWatch: {
                return {
                    id: getFormatedSku(product),
                    category: `${product.appleWatchAttributes?.departmentCodeGuizmo}|${product.appleWatchAttributes?.departmentCodeStrap}/${product.appleWatchAttributes?.familyCodeGuizmo}|${product.appleWatchAttributes?.familyCodeStrap}`,
                    variant: `${product.appleWatchAttributes?.colorHermesStrap}|${product.appleWatchAttributes?.finishGuizmo}`,
                    dimension5: `${product.appleWatchAttributes?.modelSizeGuizmo.slice(
                        -3,
                    )}`,
                    dimension6: `${product.appleWatchAttributes?.departmentCodeGuizmo}|${product.appleWatchAttributes?.departmentCodeStrap}`,
                    dimension7: `${product.appleWatchAttributes?.familyCodeGuizmo}|${product.appleWatchAttributes?.familyCodeStrap}`,
                    dimension8: `${product.appleWatchAttributes?.productCodeGuizmo}|${product.appleWatchAttributes?.productCodeStrap}`,
                    dimension9: `${product.appleWatchAttributes?.divisionCodeGuizmo}|${product.appleWatchAttributes?.divisionCodeStrap}`,
                };
            }
            case ProductTemplateType.Giftset: {
                return {
                    id: getFormatedSku(product),
                    category: `${product.departmentCode}/${product.familyCode}`,
                    variant: undefined,
                    dimension5: product.giftSetAttributes?.slot.toString(),
                    dimension6: product.departmentCode,
                    dimension7: product.familyCode,
                    dimension8: NODATA,
                    dimension9: NODATA,
                    dimension10: NODATA,
                };
            }
            case ProductTemplateType.DoubleFragrance: {
                return {
                    id: getFormatedSku(product),
                    category: `${product.doubleFragranceAttributes?.departmentCodePerfume}|${product.doubleFragranceAttributes?.departmentCodeAccessory}/${product.doubleFragranceAttributes?.familyCodePerfume}|${product.doubleFragranceAttributes?.familyCodeAccessory}`,
                    variant: undefined,
                    dimension5: undefined,
                    dimension6: `${product.doubleFragranceAttributes?.departmentCodePerfume}|${product.doubleFragranceAttributes?.departmentCodeAccessory}`,
                    dimension7: `${product.doubleFragranceAttributes?.familyCodePerfume}|${product.doubleFragranceAttributes?.familyCodeAccessory}`,
                    dimension8: `${product.doubleFragranceAttributes?.productCodePerfume}|${product.doubleFragranceAttributes?.productCodeAccessory}`,
                    dimension9: `${product.doubleFragranceAttributes?.divisionCodePerfume}|${product.doubleFragranceAttributes?.divisionCodeAccessory}`,
                    dimension10: NODATA,
                };
            }
            case ProductTemplateType.PersoSMLG:
            case ProductTemplateType.PersoBeltkit: {
                return {
                    id: getFormatedSku(product),
                    category: `${product.departmentCode}/${product.familyCode}`,
                    variant: product.simpleAttributes?.colorHermes || NODATA,
                    // eslint-disable-next-line unicorn/explicit-length-check
                    dimension5: product.size || NO_SIZE,
                    dimension6: product.departmentCode,
                    dimension7: product.familyCode,
                    dimension8: product.productCode,
                    dimension9: product.divisionCode,
                    dimension56: "no",
                };
            }

            default: {
                return undefined;
            }
        }
    }

    public getDepartementCode(product: Product): string {
        switch (product.templateType) {
            case ProductTemplateType.AppleWatch: {
                return `${product.appleWatchAttributes?.departmentCodeGuizmo}|${product.appleWatchAttributes?.departmentCodeStrap}`;
            }
            case ProductTemplateType.Beltkit: {
                return `${product.beltkitAttributes?.departmentCodeLeather}|${product.beltkitAttributes?.departmentCodeBuckle}`;
            }
            default: {
                return product.departmentCode;
            }
        }
    }

    public getDimension37(product: Product): string {
        return product.templateType === ProductTemplateType.Look ||
            product.stock?.ecom
            ? PRODUCT_IN_STOCK
            : PRODUCT_OUT_OF_STOCK;
    }

    public getStockPageviewLevel(
        product: Product,
    ): typeof PRODUCT_IN_STOCK | typeof PRODUCT_OUT_OF_STOCK {
        return product.templateType === ProductTemplateType.Look ||
            product.templateType === ProductTemplateType.Bikini ||
            product.stock?.ecom
            ? PRODUCT_IN_STOCK
            : PRODUCT_OUT_OF_STOCK;
    }

    private getListValue(): "search grid" | "product grid" | undefined {
        const menuLvl = this.eventData.menuLvl;
        if (menuLvl?.[0] === "direct") {
            return "search grid";
        } else if (menuLvl?.[0]) {
            return "product grid";
        }
        return undefined;
    }

    /**
     * Return enhanced ecommerce key for product displayed.
     */
    private getProductDetails(product: Product):
        | {
              ecommerce: { detail: { products: GTMEcommerceItemData[] } };
          }
        | undefined {
        if (product.templateType === ProductTemplateType.Look) {
            return this.getProductViewForLook(product);
        }

        if (product.templateType === ProductTemplateType.Bikini) {
            return undefined;
        }

        return {
            ecommerce: {
                detail: {
                    products: [
                        {
                            list: this.getListValue(),
                            name: product.title,
                            price: product.price
                                ? product.price.toFixed(2)
                                : "",
                            brand: HERMES_BRAND,
                            dimension37: this.getDimension37(product),
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            in_store_only:
                                product.stock &&
                                isRetailOnlyStock(product.stock)
                                    ? "yes"
                                    : "no",
                            dimension52:
                                isProductEngravable(product) ||
                                product.personalize
                                    ? YES
                                    : NO,
                            dimension53: getAvailabilityHit(product),
                            ...this.getFamilyAttributes(product),
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            VTO_available:
                                product.isVto || product.vtoType !== undefined
                                    ? YES
                                    : NO,
                            grouping_available: isGroupingAvailable(product)
                                ? YES
                                : NO,
                        },
                    ],
                },
            },
        };
    }

    private getProductViewForLook(product: Product):
        | {
              ecommerce: { detail: { products: GTMEcommerceItemData[] } };
          }
        | undefined {
        const itemWithoutVariants = product.lookAttributes?.items.filter(
            (item) => !item.variants?.sizes || item.variants.sizes.length === 0,
        );

        if (itemWithoutVariants && itemWithoutVariants.length > 0) {
            return {
                ecommerce: {
                    detail: {
                        products: itemWithoutVariants.map((item) => ({
                            list: this.getListValue(),
                            name: product.title,
                            id: item.sku,
                            price: item.price
                                ? item.price.toFixed(2)
                                : undefined,
                            brand: HERMES_BRAND,
                            category: `${item.departmentCode}/${item.familyCode}`,
                            variant: product.sku,
                            dimension5: `${product.lookAttributes?.items.length}`,
                            dimension6: item.departmentCode,
                            dimension7: item.familyCode,
                            dimension8: item.productCode,
                            dimension9: item.divisionCode,
                            dimension52: NO,
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            in_store_only: NO,
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            VTO_available: NO,
                            grouping_available: NO,
                        })),
                    },
                },
            };
        }

        return undefined;
    }

    private getInStoreOnly(product: Product): string {
        let inStoreOnly: string =
            product.stock && isRetailOnlyStock(product.stock) ? YES : NO;

        if (product.persoProductType === BK_ANALYTICS_PRODUCT_TYPE) {
            inStoreOnly = NODATA;
        }

        return inStoreOnly;
    }
}

<h-apple-pay-button
    *ngIf="displayElement.applepayButton"
    class="flex-button size-{{ size }}"
    [disabled]="isDisabled"
    [size]="size">
</h-apple-pay-button>

<ng-container
    *ngIf="
        !needToOpenModal() &&
        displayElement.termsAndConditions &&
        (applePayFacade.status$ | async) === status.TermValidating
    "
    [ngTemplateOutlet]="termsAndConditions">
</ng-container>
<ng-template #termsAndConditions>
    <h-apple-pay-terms-and-conditions [ngClass]="{ 'margin-top': product }"> </h-apple-pay-terms-and-conditions>
</ng-template>

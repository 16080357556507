<h-call-to-action
    (click)="onBuyWithApplePayClick($event)"
    [appearance]="'secondary'"
    [dataTestId]="'Buy with Apple Pay'"
    [disabled]="disabled"
    [enableSvgSizes]="false"
    [loading]="isLoading"
    [name]="'buy-with-apple-pay'"
    [size]="size"
    type="submit">
    <h-svg-icon
        [ariaHidden]="true"
        [class.disabled]="disabled"
        [name]="'payment-applepay-no-border'"
        class="payment-applepay"
        svgCenter></h-svg-icon>
    <span hSrOnlyNoSnippet i18n="@@hermes-buy-with-apple-pay" id="checkout"> Buy with Apple Pay </span>
</h-call-to-action>

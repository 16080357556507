<div class="password-input">
    <h-input
        [formControl]="control"
        [controlName]="controlName"
        [describe]="describe"
        [id]="id"
        [label]="label"
        [name]="name"
        [placeholder]="placeholder"
        [inputType]="isPasswordVisible ? 'text' : 'password'"
        [customValidatorFunction]="validate"
        [autocomplete]="autocomplete"
        [errorManagement]="errorManagement"
        [ariaDescribedby]="'id-password-input-description ' + ariaDescribedby"
        [dataTestId]="dataTestId"
        [setMinHeight]="setMinHeight"
        [mask]="mask"
        [maskPattern]="maskPattern"
        [maskSpecialCharacters]="[]"
        [maskDropSpecialCharacters]="false"
        (input)="handleInput($event)">
        <div *ngIf="errorManagement && controlHaveErrors()">
            <h-message-block
                *ngIf="control.errors?.passwordStrength"
                [showBackground]="false"
                [autoAddPadding]="false"
                [ariaRole]="undefined"
                type="error">
                <ng-container i18n="@@hermes_account.error-password-required-criterias">
                    Password as entered does not meet the required criteria
                </ng-container>
            </h-message-block>
        </div>
        <ng-content></ng-content>
    </h-input>
    <h-action-button *ngIf="seeShowLink" (click)="isPasswordVisible = !isPasswordVisible" [size]="'medium'">
        <ng-container *ngIf="!isPasswordVisible" i18n="@@hermes_global.show">Show</ng-container>
        <ng-container *ngIf="isPasswordVisible" i18n="@@hermes_global.hide">Hide</ng-container>
        <span hSrOnlyNoSnippet i18n="@@hermes_account.checkout_password"> Password</span>
    </h-action-button>
</div>

<div *ngIf="seeRequiredCriterias" id="id-password-input-description" class="required-criterias" [attr.role]="'alert'">
    <p i18n="@@hermes_account.error-password-criterias"
        >For your security, we invite you to fill your password according to the following criterias:</p
    >
    <ul>
        <li
            class="length-criteria"
            [ngClass]="isEmpty ? 'criteria-empty' : hasMinLength ? 'criteria-valid' : 'criteria-invalid'">
            <ng-container [ngTemplateOutlet]="isEmpty ? null : hasMinLength ? check : cross"></ng-container>
            <span i18n="@@hermes_account.minimum-10-characters">at least 10 characters</span>
            <ng-container [ngTemplateOutlet]="hasMinLength ? checked : unchecked"></ng-container>
        </li>
        <li
            class="uppercase-criteria"
            [ngClass]="isEmpty ? 'criteria-empty' : hasUpperCase ? 'criteria-valid' : 'criteria-invalid'">
            <ng-container [ngTemplateOutlet]="isEmpty ? null : hasUpperCase ? check : cross"></ng-container>
            <span i18n="@@hermes_account.minimum-1-uppercase-character">1 uppercase letter</span>
            <ng-container [ngTemplateOutlet]="hasUpperCase ? checked : unchecked"></ng-container>
        </li>
        <li
            class="lowercase-criteria"
            [ngClass]="isEmpty ? 'criteria-empty' : hasLowerCase ? 'criteria-valid' : 'criteria-invalid'">
            <ng-container [ngTemplateOutlet]="isEmpty ? null : hasLowerCase ? check : cross"></ng-container>
            <span i18n="@@hermes_account.minimum-1-lowercase-character">1 lowercase letter</span>
            <ng-container [ngTemplateOutlet]="hasLowerCase ? checked : unchecked"></ng-container>
        </li>
        <li
            class="number-criteria"
            [ngClass]="isEmpty ? 'criteria-empty' : hasNumeric ? 'criteria-valid' : 'criteria-invalid'">
            <ng-container [ngTemplateOutlet]="isEmpty ? null : hasNumeric ? check : cross"></ng-container>
            <span i18n="@@hermes_account.minimum-1-number">1 number</span>
            <ng-container [ngTemplateOutlet]="hasNumeric ? checked : unchecked"></ng-container>
        </li>
        <li
            class="special-criteria"
            [ngClass]="isEmpty ? 'criteria-empty' : hasSpecial ? 'criteria-valid' : 'criteria-invalid'">
            <ng-container [ngTemplateOutlet]="isEmpty ? null : hasSpecial ? check : cross"></ng-container>
            <span i18n="@@hermes_account.minimum-1-special-character">1 special character</span>
            <ng-container [ngTemplateOutlet]="hasSpecial ? checked : unchecked"></ng-container>
        </li>
    </ul>

    <ng-template #check>
        <h-svg-icon name="checkmark"></h-svg-icon>
    </ng-template>

    <ng-template #cross>
        <h-svg-icon name="crossmark"></h-svg-icon>
    </ng-template>

    <ng-template #checked>
        <span hSrOnlyNoSnippet>
            <span i18n="@@hermes.accessibility.colon-separator">:&nbsp;</span>
            <span i18n="@@hermes.accessibility.password-criterias-checked">Checked</span>
            <span i18n="@@hermes.accessibility.comma-separator">,&nbsp;</span>
        </span>
    </ng-template>

    <ng-template #unchecked>
        <span hSrOnlyNoSnippet>
            <span i18n="@@hermes.accessibility.colon-separator">:&nbsp;</span>
            <span i18n="@@hermes.accessibility.password-criterias-not-checked">Unchecked</span>
            <span i18n="@@hermes.accessibility.comma-separator">,&nbsp;</span>
        </span>
    </ng-template>
</div>

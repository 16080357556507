import {
    Component,
    ElementRef,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core";
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { IConfig } from "ngx-mask";
import { filter, Subject, Subscription } from "rxjs";

import { LayoutFacade } from "@hermes/aphrodite/layout";
import { Context, LOCALE, WINDOW } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { isChromeAgent } from "@hermes/utils/helpers";
import { getAreaCode } from "@hermes/utils-generic/helpers";

/**
 * This component implements a global phone code + phone number input Component.
 *
 * It uses the Reactive Forms to manage controls and data and implements the ControlValueAccessor interface.
 *
 * @see https://angular.io/guide/reactive-forms
 * @see https://angular.io/guide/forms-overview#common-form-foundation-classes
 *
 * You must use this input with a FormGroup : `formControlName` must have the name of the AbstractControl.
 *
 * It manages basic forms errors for you :
 * * Required error
 * * AreaCode format error
 * * Phone number format error
 *
 * You can also add errors message yourself (see example)
 *
 * Usage:
 * ```
 *  <h-phone-input
 *      [formControlName]="'field'"
 *      [setMinHeight]="false"
 *      areaCodeLabel="'My label translated into english'"
 *      phoneLabel="'My label translated into english'"
 *      i18n-areaCodeLabel="@@my.key.translate"
 *      i18n-phoneLabel="@@my.key.translate">
 *
 *      <h-message-block *ngIf="serviceError" [type]="'error'"">
 *             <ng-container i18n="@@my.key.translate">
 *                    New service error
 *             </ng-container>
 *      </h-message-block>
 *
 *  </h-phone-input>
 * ```
 *
 */

@Component({
    selector: "h-phone-input",
    templateUrl: "./phone-input.component.html",
    styleUrls: ["./phone-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneInputComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PhoneInputComponent),
            multi: true,
        },
    ],
})
export class PhoneInputComponent
    implements ControlValueAccessor, Validator, OnInit, OnDestroy
{
    public static readonly areaCodeValidator =
        Validators.pattern(/^\+(\d{1,4})$/);
    @ViewChild("phoneInput")
    public phoneInput!: ElementRef<HTMLInputElement>;

    /**
     * Label of the areaCode input
     * To use the translate system with this label, you can used "i18n-areaCodeLabel="@@my.trad"
     */
    @Input()
    public areaCodeLabel!: string;

    /**
     * Label of the phone input
     * To use the translate system with this label, you can used "i18n-phoneLabel="@@my.trad"
     */
    @Input()
    public phoneLabel!: string;

    @Input()
    public formControlName!: string;

    @Input()
    public showJapanLabel?: boolean = false;

    @Input()
    public suffixId?: string;

    @Input()
    public dataTestId?: string;

    // When true, sets a min height to the component in order for the error messages to display without moving other elements
    @Input()
    public setMinHeight: boolean = false;

    @Input()
    public selectedCountryCode?: string;

    public focusOut$: Subject<void> = new Subject();

    public touched!: () => void;

    public subscription: Subscription = new Subscription();

    public phoneControl: AbstractControl = this.formBuilder.control("", [
        Validators.required,
        Validators.minLength(8),
    ]);

    public areaCodeControl: AbstractControl = this.formBuilder.control("", [
        Validators.required,
        PhoneInputComponent.areaCodeValidator,
    ]);

    public phoneWithAreaCodeMask: string = "CDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD";
    public phoneWithAreaCodeMaskPattern: IConfig["patterns"] = {
        C: { pattern: /[1-9]$/ },
        D: { pattern: /\d$/, optional: true },
        E: { pattern: /\d$/, optional: false },
    };

    public areaCodeMask: string = "0999";

    public phoneWithAreaCodeForm: UntypedFormGroup = this.formBuilder.group(
        {
            phone: this.phoneControl,
            areaCode: this.areaCodeControl,
        },
        { updateOn: "blur" },
    );

    public autocomplete: string = "tel-national";

    constructor(
        private formBuilder: UntypedFormBuilder,
        private controlContainer: ControlContainer,
        public layoutFacade: LayoutFacade,
        @Inject(LOCALE) private locale: Locale,
        @Inject(WINDOW) private window: Window,
        private context: Context,
    ) {
        // Do nothing
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public ngOnInit(): void {
        this.areaCodeControl.setValue(
            `+${getAreaCode(
                this.selectedCountryCode?.toLowerCase() || "",
                this.locale.countryCode,
            )}`,
        );

        this.subscription.add(
            this.focusOut$
                .pipe(
                    filter(
                        () =>
                            this.phoneControl.touched ||
                            this.areaCodeControl.touched,
                    ),
                )
                .subscribe(() => {
                    this.touched();
                }),
        );

        /**
         * Because of `touched` event doesnt realy exists when we implements ControlValueAccessor
         * we have to implements manually the reaction to the `markAsTouched` function from parent Control.
         *
         * @see https://github.com/angular/angular/issues/10887 for tracking the issue.
         */
        const control = this.controlContainer.control;
        if (!control) {
            // Should never be the case with proper inputs, it is to satisfy Typescript's typestrict-ness
            return;
        }

        const formControl = control.get(this.formControlName);

        if (!formControl) {
            // Should never be the case with proper inputs, it is to satisfy Typescript's typestrict-ness
            return;
        }

        formControl.markAsTouched = () => {
            this.phoneWithAreaCodeForm.markAllAsTouched();
        };

        if (
            this.context.isInBrowserMode() &&
            isChromeAgent(this.window.navigator.userAgent)
        ) {
            this.autocomplete = "faketel-national";
        }
    }

    public writeValue(value: { phone: string; areaCode: string }): void {
        if (value) {
            this.phoneWithAreaCodeForm.patchValue(value, { emitEvent: false });
        }
    }

    public registerOnChange(
        onChangeFunction: (value: { phone: string; areaCode: string }) => void,
    ): void {
        this.subscription.add(
            this.phoneWithAreaCodeForm.valueChanges.subscribe(onChangeFunction),
        );
    }

    public registerOnValidatorChange(onValidatorFunction: () => void): void {
        this.subscription.add(
            this.phoneWithAreaCodeForm.statusChanges.subscribe(
                onValidatorFunction,
            ),
        );
    }

    public registerOnTouched(touchedFunction: () => void): void {
        this.touched = touchedFunction;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.phoneWithAreaCodeForm.disable();
        } else {
            this.phoneWithAreaCodeForm.enable();
        }
    }

    public validate(): ValidationErrors {
        return {
            ...this.phoneControl.errors,
            ...this.areaCodeControl.errors,
        };
    }

    public controlHaveErrors(control: AbstractControl): boolean {
        if (control.value?.includes("++")) {
            control.setValue(control.value.replace("++", "+"));
        }

        const parentControl = this.controlContainer.control?.get(
            this.formControlName,
        );

        return (
            (control.invalid && (control.dirty || control.touched)) ||
            ((parentControl &&
                parentControl.invalid &&
                (parentControl.dirty || parentControl.touched)) ??
                false)
        );
    }

    /**
     * Force the blur event to update value and validity of the phoneInput Control
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event
     */
    public forceBlur(event: Event): void {
        const eventKeyboard = event as KeyboardEvent;
        // In case the user keeps pressing the key, the event is cancelled.
        if (eventKeyboard.repeat) {
            event.preventDefault();
            return;
        }

        const focusEvent = new FocusEvent("blur");
        this.phoneInput.nativeElement.dispatchEvent(focusEvent);
    }

    public getAreaCodeId(): string {
        return this.suffixId ? `${this.suffixId}-area-code` : "area-code";
    }

    public getPhoneId(): string {
        return this.suffixId ? `${this.suffixId}-phone` : "phone";
    }

    public focusInput(): void {
        this.phoneInput.nativeElement.focus();
    }
}

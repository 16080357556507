import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { CtaSize, LARGE } from "@hermes/aphrodite/call-to-action";
import { Context } from "@hermes/app-core";
import { ApplePayFacade, ApplepayStatus } from "@hermes/states/apple-pay";

@Component({
    selector: "h-apple-pay-button",
    templateUrl: "./apple-pay-button.component.html",
    styleUrls: ["./apple-pay-button.component.scss"],
})
export class ApplePayButtonComponent implements OnInit, OnDestroy {
    @Input()
    public disabled = false;

    @Input()
    public preventSubmit = true;

    @Input()
    public size: CtaSize = LARGE;

    public isLoading = false;

    public status = ApplepayStatus;

    private subscriptions: Subscription = new Subscription();

    constructor(
        private context: Context,
        public applePayFacade: ApplePayFacade,
    ) {}

    public ngOnInit(): void {
        if (this.context.isInServerMode()) {
            return;
        }
        this.subscriptions.add(
            this.applePayFacade.status$.subscribe((status) => {
                this.isLoading = [
                    ApplepayStatus.AddingProductToCart,
                    ApplepayStatus.CheckingForm,
                ].includes(status);
            }),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions?.unsubscribe();
    }

    public onBuyWithApplePayClick($event: Event): void {
        if (this.preventSubmit) {
            $event.stopImmediatePropagation();
            this.applePayFacade.clickOnApplepayButton();
        }
    }
}

import { Injectable, Signal } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { BasketItem } from "@hermes/api-model-basket";
import { Product } from "@hermes/api-model-product";
import { RecaptchaService } from "@hermes/utils-generic/services/client-api";

import * as PaymentActions from "../actions/payment-methods.actions";
import { isApplePayMethodHelper } from "../helpers";
import { ApplePayPage } from "../models/apple-pay-page.model";
import { ExclusionCodes } from "../models/exclusion-codes.model";
import { FetchPaymentMethodsOptions } from "../models/fetch-payment-methods-options.model";
import { PaymentMethods, State } from "../reducers/payment-methods.reducer";
import paymentQuery, {
    selectSavedPaymentCardsState,
} from "../selectors/payment-methods.selectors";
import { AvailablePaymentMethodsService } from "../services/available-payment-methods.service";

@Injectable()
export class PaymentMethodsFacade {
    /**
     * Selectors
     */
    public adyenCreditCardPaymentMethod$ = this.store.select(
        paymentQuery.selectAdyenCreditCardPaymentMethod,
    );

    public getSavedPaymentCards$ = this.store.select(
        paymentQuery.selectSavedPaymentCards,
    );
    public pendingSignal: Signal<boolean> = this.store.selectSignal(
        selectSavedPaymentCardsState,
    );

    constructor(
        private store: Store<State>,
        private availablePaymentMethodsService: AvailablePaymentMethodsService,
        private recaptchaService: RecaptchaService,
    ) {}

    public isApplePayToDisplay(
        applePayPage?: ApplePayPage,
        items?: Array<Product | BasketItem>,
    ): Observable<boolean> {
        return this.availablePaymentMethodsService
            .getExclusionsCodeFromItems(items ?? [])
            .pipe(
                switchMap((exclusionCodes) =>
                    this.getAvailablePaymentMethods({
                        applePayPage,
                        productCodes: exclusionCodes,
                    }),
                ),
                switchMap((paymentMethods) =>
                    paymentMethods.some((pm) => isApplePayMethodHelper(pm.code))
                        ? this.recaptchaService
                              .loadExternalRecaptchaLibraryIfNeeded()
                              .pipe(map((result) => result ?? true))
                        : of(false),
                ),
            );
    }

    /**
     * Actions
     */
    public fetchPaymentMethods(options?: FetchPaymentMethodsOptions): void {
        this.store.dispatch(PaymentActions.fetchPaymentMethods({ options }));
    }

    public deleteSavedCard(id: string, cardToken: string): void {
        this.store.dispatch(PaymentActions.deleteSavedCard({ id, cardToken }));
    }

    public removeCard(id: string): void {
        this.store.dispatch(PaymentActions.removeCard({ id }));
    }

    public getAvailablePaymentMethods(
        parameters: {
            isWeChat?: boolean;
            total?: number;
            hasRecurring?: boolean;
            applePayPage?: ApplePayPage;
            productCodes?: ExclusionCodes;
        } = {},
    ): Observable<PaymentMethods> {
        return this.store
            .select(paymentQuery.selectPaymentMethodsData)
            .pipe(
                map((paymentMethods) =>
                    this.availablePaymentMethodsService.getAvailablePaymentMethods(
                        paymentMethods,
                        parameters,
                    ),
                ),
            );
    }
}

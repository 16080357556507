import { DOCUMENT, Location } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, Renderer2 } from "@angular/core";
import { JsonConvert } from "json2typescript";
import { Observable, Subject } from "rxjs";
import { catchError, map } from "rxjs/operators";

import {
    BrowserData,
    PaymentRequest,
    PaymentResponse,
    CustomerOrigin,
    FeatureType,
} from "@hermes/api-model-payment";

import {
    Context,
    LOCALE,
    Settings,
    StorageService,
    WINDOW,
} from "@hermes/app-core";
import { CONFIRMATION_PAGE } from "@hermes/fragments/checkout-breadcrumb";
import { Locale } from "@hermes/locale";
import { BasketFacade } from "@hermes/states/basket";
import { UserStateService } from "@hermes/states/user";
import {
    EcomErrorCodes,
    HTTP_JSON_CONTENT_TYPE_HEADER,
} from "@hermes/utils-generic/constants";

import { UrlUtils } from "@hermes/utils-generic/services/user-interface";

import {
    LEGACY_CUP,
    MERCURY_ADYEN_PAYPAL,
    QRCODE_VALIDATION_PIX,
    QRCODE_VALIDATION_WECHAT,
} from "../constants";
import { AdyenCreditCardPaymentMethod } from "../models/adyen-credit-card-payment-method.model";
import { CheckoutCreditCardPaymentMethod } from "../models/checkout-credit-card-payment-method.model";
import { PaymentMethodsResponseDTO } from "../models/payment-methods-response-dto.model";

export type ProcessingChannel =
    typeof PaymentRequest.prototype.processingChannel;

interface WindowWC extends Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    WeixinJSBridge: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        invoke: (a: string, b: any, c: (a1: any) => void) => void;
    };
}

@Injectable()
export class PaymentService {
    public productOutOfStock$: Subject<{ sku: string; internalCode: string }>;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(LOCALE) public locale: Locale,
        @Inject(WINDOW) private window: WindowWC,
        private basketFacade: BasketFacade,
        private http: HttpClient,
        private settings: Settings,
        private storageService: StorageService,
        private context: Context,
        private userService: UserStateService,
        private location: Location,
        private urlUtils: UrlUtils,
    ) {
        this.productOutOfStock$ = new Subject<{
            sku: string;
            internalCode: string;
        }>();
    }

    public placePayment(
        paymentRequest: PaymentRequest,
    ): Observable<PaymentResponse> {
        return this.http
            .post(`${this.settings.apiUrl}/payment`, paymentRequest, {
                headers: new HttpHeaders({
                    ...HTTP_JSON_CONTENT_TYPE_HEADER,
                }),
            })
            .pipe(
                map((data: unknown) => {
                    const deserializedData =
                        new JsonConvert().deserializeObject(
                            data,
                            PaymentResponse,
                        );
                    if (
                        deserializedData.internalCode ===
                        EcomErrorCodes.OUT_OF_STOCK_WHEN_PAYMENT
                    ) {
                        const sku: string = deserializedData.message
                            .split('"')[1]
                            .split("Not enough stock for product ")[1];
                        this.productOutOfStock$.next({
                            sku,
                            internalCode:
                                EcomErrorCodes.OUT_OF_STOCK_WHEN_PAYMENT,
                        });
                    }
                    return deserializedData;
                }),
                catchError(this.errorHandler("Payment error")),
            );
    }

    public handlePaymentResponse(
        paymentResponse: PaymentResponse,
        applePayWidget?: boolean,
    ): void {
        this.location.replaceState("checkout");
        this.userService.removeCheckout();
        this.basketFacade.clearSessionData();
        this.userService.setAnalyticsBasket(paymentResponse, applePayWidget);
    }

    public handlePaymentValidation(
        paymentResponse: PaymentResponse,
        renderer?: Renderer2,
    ): void {
        if (this.isQrCodePaymentType(paymentResponse)) {
            // on new-customer we need to fetch session datas to get an ID for weChat polling
            this.handleQrCodePaymentValidation(paymentResponse);
            return;
        }

        // Redirect case
        if (
            renderer &&
            (paymentResponse.paymentType === "redirect" ||
                paymentResponse.paymentType === LEGACY_CUP)
        ) {
            this.handlePaymentRedirect(paymentResponse, renderer);
            return;
        }

        // Confirmation case
        if (paymentResponse.paymentType === "confirmation") {
            this.handlePaymentConfirmation(paymentResponse.orderId);
        }
    }

    /**
     * Return credit card type from available-payment-methods response
     */
    public getAcceptedCreditCards(
        paymentMethods: PaymentMethodsResponseDTO,
        paymentMethod:
            | AdyenCreditCardPaymentMethod["code"]
            | CheckoutCreditCardPaymentMethod["code"],
    ): string[] {
        const creditCardPm = paymentMethods.find(
            (pm) => pm.code === paymentMethod,
        );
        return creditCardPm && "brands" in creditCardPm
            ? creditCardPm.brands
            : [];
    }

    public createPaymentRequest(paymentRequestData: {
        paymentMethod: string;
        applePayToken?: string;
        paypalClientId?: string;
        processingChannel: ProcessingChannel;
    }): PaymentRequest {
        return {
            payment_method: paymentRequestData.paymentMethod,
            locale: this.locale.code,
            userAgent: this.window.navigator.userAgent,
            url_redirect: `${
                this.context.getCurrentOrigin() + this.locale.urlPrefix
            }/payment/validation`,
            browserInfo: {
                language: navigator.language,
                screenHeight: window.innerHeight,
                screenWidth: window.innerWidth,
                colorDepth: screen.colorDepth,
                timeZoneOffset: new Date().getTimezoneOffset(),
            } as BrowserData,
            ...(paymentRequestData.applePayToken && {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                payment_data: {
                    applePayData: paymentRequestData.applePayToken,
                },
            }),
            ...(paymentRequestData.paymentMethod === MERCURY_ADYEN_PAYPAL && {
                paypalSubtype: paymentRequestData.paypalClientId
                    ? "sdk"
                    : "redirect",
            }),
            processingChannel: paymentRequestData.processingChannel,
            ...(this.storageService.getSessionStorageItem(
                "backInStockFromEmailNotification",
            ) === true && {
                trackingInformation: {
                    feature: FeatureType.BACK_IN_STOCK,
                    origin: this.storageService.getSessionStorageItem(
                        "backInStockFromEmailNotification",
                    )
                        ? CustomerOrigin.EMAIL
                        : CustomerOrigin.UNKNOWN,
                    information: this.storageService.getSessionStorageItem(
                        "productSkuBackInStock",
                    ),
                },
            }),
        };
    }

    public handlePaymentRedirect(
        paymentResponse: PaymentResponse,
        renderer: Renderer2,
    ): void {
        const isMercuryPayment = !!paymentResponse.mercuryRedirect;
        if (
            isMercuryPayment &&
            paymentResponse.mercuryRedirect.method === "GET"
        ) {
            this.window.location.href = paymentResponse.mercuryRedirect.url;
            return;
        }

        if (
            !isMercuryPayment &&
            typeof paymentResponse.formFields === "string"
        ) {
            paymentResponse.formFields = JSON.parse(paymentResponse.formFields);
        }

        if (paymentResponse.paymentType === "hsbc_cup") {
            return this.handleHSBCPaymentRedirect(paymentResponse, renderer);
        }

        // Other Mercury redirect and Classical Adyen redirect are done with POST requests
        const form = renderer.createElement("form");
        const body = renderer.selectRootElement("body", true);

        const formAction = isMercuryPayment
            ? paymentResponse.mercuryRedirect.url
            : paymentResponse.formAction;
        const formFields = isMercuryPayment
            ? paymentResponse.mercuryRedirect.data
            : paymentResponse.formFields;

        form.setAttribute("method", "post");
        form.setAttribute("action", formAction);

        Object.keys(formFields).forEach((key) => {
            const input = renderer.createElement("input");
            renderer.setAttribute(input, "type", "hidden");
            renderer.setAttribute(input, "name", key);
            renderer.setAttribute(input, "value", formFields[key]);
            renderer.appendChild(form, input);
        });

        renderer.appendChild(body, form);

        form.submit();
    }

    public handleQrCodePaymentValidation(
        paymentResponse: PaymentResponse,
    ): void {
        // PIX and mercury_wechat
        const isMercuryDesktop = [
            paymentResponse.formFields?.method,
            paymentResponse.mercuryRedirect?.method,
        ].includes("QRCODE");

        // initiated with wechat legacy desktop or mobile
        let urlToRedirect =
            paymentResponse.formFields?.qrcode_page ||
            paymentResponse.formFields?.intermediate_page;
        const isLegacyWechatMobile =
            urlToRedirect === "payment-validation/validatewcpmobile";
        const isMercuryWechatMobile =
            paymentResponse.formFields?.method === "GET";

        const validationPage =
            isMercuryWechatMobile || isLegacyWechatMobile
                ? "validatewcpmobile"
                : "qrcode";

        const url =
            paymentResponse.mercuryRedirect?.url ??
            paymentResponse.formFields?.url ??
            paymentResponse.formFields?.code_url ??
            paymentResponse.formFields?.mweb_url;
        const amount =
            paymentResponse.amount ??
            Number(
                paymentResponse.formFields?.order_amount?.replace(
                    /[^\d.]/g,
                    "",
                ),
            );

        if (
            isLegacyWechatMobile ||
            (!urlToRedirect && (isMercuryDesktop || isMercuryWechatMobile))
        ) {
            const method =
                paymentResponse.paymentType === QRCODE_VALIDATION_WECHAT
                    ? paymentResponse.paymentType
                    : paymentResponse.method;

            this.storageService.setSessionStorageItem(
                "payment_validation_data",
                {
                    url,
                    paymentMethod: method,
                    amount,
                    orderId: paymentResponse.orderId,
                    expirationDate: paymentResponse.expirationDate,
                },
            );
            urlToRedirect = `payment-validation/${validationPage}/`;
        }

        return urlToRedirect
            ? this.urlUtils.redirectToInternalUrl(urlToRedirect)
            : // Wechat in-app legacy && Wechat in-app Mercury
              this.handleWechatInAppPayment(paymentResponse);
    }

    public handlePaymentConfirmation(orderId: string): void {
        this.urlUtils.redirectToPage(CONFIRMATION_PAGE, orderId);
    }

    public isQrCodePaymentType(paymentResponse: PaymentResponse): boolean {
        const method =
            paymentResponse.paymentType === QRCODE_VALIDATION_WECHAT
                ? paymentResponse.paymentType
                : paymentResponse.method;

        return (
            !!method &&
            [QRCODE_VALIDATION_WECHAT, QRCODE_VALIDATION_PIX].includes(
                method.toLocaleLowerCase(),
            )
        );
    }

    private handleWechatInAppPayment(response: PaymentResponse): void {
        this.window["WeixinJSBridge"].invoke(
            "getBrandWCPayRequest",
            response.formFields,
            (result) => {
                if (result.err_msg === "get_brand_wcpay_request:ok") {
                    this.urlUtils.redirectToPage(
                        CONFIRMATION_PAGE,
                        response.orderId,
                    );
                } else {
                    this.errorHandler("Payment error");
                }
            },
        );
    }

    private errorHandler(message: string) {
        return () => {
            throw new Error(`${message}`);
        };
    }

    private handleHSBCPaymentRedirect(
        paymentResponse: PaymentResponse,
        renderer: Renderer2,
    ) {
        const body = renderer.selectRootElement("body", true);
        const div = renderer.createElement("div");
        const form = decodeURIComponent(
            paymentResponse.formFields.htmlPage.replace(/\+/g, "%20"),
        );
        div.innerHTML = form;
        renderer.appendChild(body, div);
        this.storageService.setSessionStorageItem(
            "hsbc_order_no",
            paymentResponse.orderId,
        );
        const payForm = this.document.getElementById("pay_form");
        // HSBC_CUP on desktop
        if (payForm) {
            (payForm as HTMLFormElement).submit();
        }
        // HSBC_CUP on mobile
        else {
            (
                this.document.getElementById(
                    "pc_cashier_form",
                ) as HTMLFormElement
            ).submit();
        }
    }
}

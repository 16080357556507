import {
    LEGACY_ALIPAY,
    LEGACY_CUP,
    LEGACY_WECHAT,
    MERCURY_ADYEN_APPLE_PAY,
    MERCURY_ADYEN_CREDITCARD,
    MERCURY_ADYEN_KCP_CC,
    MERCURY_ADYEN_KCP_NAVERPAY,
    MERCURY_ADYEN_KCP_PAYCO,
    MERCURY_ADYEN_ONE_CLICK,
    MERCURY_ADYEN_PAYPAL,
    MERCURY_ADYEN_PIX,
    MERCURY_ADYEN_SAVEDCARD,
    MERCURY_ALIPAY,
    MERCURY_CHECKOUTCOM_ALIPAY,
    MERCURY_CHECKOUTCOM_CC,
    MERCURY_PAYPAL,
    MERCURY_WECHAT,
    STRIPE_CC,
} from "../constants";

export const isCreditCardPaymentHelper = (paymentMethod: string): boolean =>
    [
        MERCURY_ADYEN_CREDITCARD,
        MERCURY_ADYEN_SAVEDCARD,
        MERCURY_CHECKOUTCOM_CC,
        STRIPE_CC,
    ].includes(paymentMethod);

export const isAdyenCreditCardHelper = (paymentMethod: string): boolean =>
    MERCURY_ADYEN_CREDITCARD === paymentMethod;

export const isCheckoutCreditCardHelper = (paymentMethod: string): boolean =>
    MERCURY_CHECKOUTCOM_CC === paymentMethod;

export const isPaypalHelper = (paymentMethod: string): boolean =>
    [MERCURY_ADYEN_PAYPAL, MERCURY_PAYPAL].includes(paymentMethod);

export const isPixHelper = (paymentMethod: string): boolean =>
    MERCURY_ADYEN_PIX === paymentMethod;

export const isAlipayHelper = (paymentMethod: string): boolean =>
    [LEGACY_ALIPAY, MERCURY_ALIPAY, MERCURY_CHECKOUTCOM_ALIPAY].includes(
        paymentMethod,
    );

export const isWechatHelper = (paymentMethod: string): boolean =>
    [MERCURY_WECHAT, LEGACY_WECHAT].includes(paymentMethod);

export const isHsbcHelper = (paymentMethod: string): boolean =>
    LEGACY_CUP === paymentMethod;

export const isApplePayMethodHelper = (paymentMethod: string): boolean =>
    MERCURY_ADYEN_APPLE_PAY === paymentMethod;

export const isKcpCreditCardMethodHelper = (paymentMethod: string): boolean =>
    MERCURY_ADYEN_KCP_CC === paymentMethod;

export const isKcpPaycoMethodHelper = (paymentMethod: string): boolean =>
    MERCURY_ADYEN_KCP_PAYCO === paymentMethod;

export const isKcpNaverpayMethodHelper = (paymentMethod: string): boolean =>
    MERCURY_ADYEN_KCP_NAVERPAY === paymentMethod;

export const isAdyenOneClickPaymentMethodHelper = (
    paymentMethod: string,
): boolean => MERCURY_ADYEN_ONE_CLICK === paymentMethod;

export const isHPPRedirectPaymentMethodHelper = (
    paymentMethod: string,
): boolean =>
    [
        LEGACY_ALIPAY,
        LEGACY_CUP,
        LEGACY_WECHAT,
        MERCURY_ADYEN_KCP_CC,
        MERCURY_ADYEN_KCP_NAVERPAY,
        MERCURY_ADYEN_KCP_PAYCO,
        MERCURY_ADYEN_PIX,
        MERCURY_ALIPAY,
        MERCURY_WECHAT,
        MERCURY_CHECKOUTCOM_ALIPAY,
    ].includes(paymentMethod);

export const isPaymentMethodHelper = (
    paymentCode: string,
    paymentMethod: string,
): boolean => {
    if (paymentMethod in helpersMapper) {
        return helpersMapper[paymentMethod](paymentCode);
    }
    return false;
};

const helpersMapper: Record<string, (paymentMethod: string) => boolean> = {
    hsbc: isHsbcHelper,
    wechat: isWechatHelper,
    alipay: isAlipayHelper,
    paypal: isPaypalHelper,
    pix: isPixHelper,
    creditcard: isCreditCardPaymentHelper,
    adyenCreditCards: isAdyenCreditCardHelper,
    applePay: isApplePayMethodHelper,
    kcpCreditCard: isKcpCreditCardMethodHelper,
    kcpPayco: isKcpPaycoMethodHelper,
    kcpNaverpay: isKcpNaverpayMethodHelper,
    hppRedirect: isHPPRedirectPaymentMethodHelper,
};

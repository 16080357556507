import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";

import { PaymentMethodsStateModule } from "@hermes/states/payment-methods";

import { ApplePayFacade } from "./facades/apple-pay.facade";
import {
    applePayFeatureKey,
    reducer as applePayReducer,
} from "./reducers/apple-pay.reducer";
import { ApplePaySessionService } from "./services/apple-pay-session.service";
import { ApplePayService } from "./services/apple-pay.service";

import { CustomerService } from "@hermes/fragments/customer";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(applePayFeatureKey, applePayReducer),
        PaymentMethodsStateModule,
    ],
    providers: [
        ApplePayFacade,
        ApplePayService,
        ApplePaySessionService,
        CustomerService,
    ],
})
export class ApplePayStateModule {}

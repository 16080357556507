import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { LOCALE, Settings } from "@hermes/app-core";
import { Locale } from "@hermes/locale";

@Injectable()
export class SavedCardService {
    constructor(
        private http: HttpClient,
        private settings: Settings,
        @Inject(LOCALE) private locale: Locale,
    ) {}

    public deleteSavedCard(
        cardId: string,
        cardToken: string,
    ): Observable<string> {
        const params = new HttpParams()
            .set("locale", this.locale.code)
            .set("cardToken", cardToken);

        return this.http
            .delete(`${this.settings.apiUrl}/cards/${cardId}`, {
                params,
            })
            .pipe(map(() => cardId));
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

import { ButtonModule } from "@hermes/aphrodite/call-to-action";
import { FieldsModule } from "@hermes/aphrodite/fields";
import { SvgModule, svgPaymentApplepayNoBorder } from "@hermes/aphrodite/svg";
import { ApplePayStateModule } from "@hermes/states/apple-pay";
import { BasketStateModule } from "@hermes/states/basket";
import { PaymentMethodsStateModule } from "@hermes/states/payment-methods";
import { SrOnlyNoSnippetDirective } from "@hermes/utils-generic/directives/sr-only-no-snippet";
import { BreakpointService } from "@hermes/utils-generic/services/user-interface";

import { ApplePayButtonComponent } from "./components/apple-pay-button/apple-pay-button.component";
import { ApplePayTermsAndConditionsComponent } from "./components/apple-pay-terms-and-conditions/apple-pay-terms-and-conditions.component";
import { CallToActionApplePayComponent } from "./components/call-to-action-apple-pay/call-to-action-apple-pay.component";
import { ProductService } from "./services/product.service";

@NgModule({
    declarations: [
        ApplePayButtonComponent,
        ApplePayTermsAndConditionsComponent,
        CallToActionApplePayComponent,
    ],
    exports: [
        ApplePayButtonComponent,
        ApplePayTermsAndConditionsComponent,
        CallToActionApplePayComponent,
    ],
    providers: [BreakpointService, ProductService],
    imports: [
        BasketStateModule,
        ButtonModule,
        CommonModule,
        FieldsModule,
        SvgModule.forChild([svgPaymentApplepayNoBorder]),
        TranslateModule.forChild({}),
        ReactiveFormsModule,
        SrOnlyNoSnippetDirective,
        PaymentMethodsStateModule,
        ApplePayStateModule,
    ],
})
export class ApplePayModule {}

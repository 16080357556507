import { A11yModule } from "@angular/cdk/a11y";
import { OverlayModule } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { TranslateModule } from "@ngx-translate/core";
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";

import { ActionButtonComponent } from "@hermes/aphrodite/action-button";
import { MessageBlockModule } from "@hermes/aphrodite/message-block";
import { SeparatorModule } from "@hermes/aphrodite/separator";
import {
    SvgModule,
    svgArrowRounded,
    svgCheckmark,
    svgCrossRounded,
    svgCrossmark,
    svgSearch,
} from "@hermes/aphrodite/svg";

import { SrOnlyNoSnippetDirective } from "@hermes/utils-generic/directives/sr-only-no-snippet";

import { ViewportService } from "@hermes/utils-generic/services/viewport";

import { CheckboxComponent } from "./checkbox/checkbox.component";
import { DateSelectorComponent } from "./date-selector/date-selector.component";
import { EmailInputComponent } from "./email-input/email-input.component";
import { AriaDescribedbyPipe } from "./input/ariadescribedby.pipe";
import { InputComponent } from "./input/input.component";
import { PasswordInputComponent } from "./password-input/password-input.component";
import { PhoneInputComponent } from "./phone-input/phone-input.component";
import { SearchInputComponent } from "./search-input/search-input.component";
import { SelectInputComponent } from "./select-input/select-input.component";
import { TextareaInputComponent } from "./textarea-input/textarea-input.component";
@NgModule({
    declarations: [
        CheckboxComponent,
        EmailInputComponent,
        InputComponent,
        PasswordInputComponent,
        PhoneInputComponent,
        SearchInputComponent,
        DateSelectorComponent,
        SelectInputComponent,
        TextareaInputComponent,
    ],
    exports: [
        CheckboxComponent,
        EmailInputComponent,
        InputComponent,
        PasswordInputComponent,
        PhoneInputComponent,
        SearchInputComponent,
        DateSelectorComponent,
        SelectInputComponent,
        TextareaInputComponent,
    ],
    providers: [provideNgxMask(), ViewportService],
    imports: [
        ActionButtonComponent,
        A11yModule,
        CommonModule,
        FormsModule,
        MessageBlockModule,
        OverlayModule,
        ReactiveFormsModule,
        SrOnlyNoSnippetDirective,
        SeparatorModule,
        SvgModule.forChild([
            svgCrossRounded,
            svgSearch,
            svgArrowRounded,
            svgCheckmark,
            svgCrossmark,
        ]),
        NgxMaskDirective,
        AriaDescribedbyPipe,
        TranslateModule,
    ],
})
export class FieldsModule {}

import { CommonModule } from "@angular/common";
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild,
} from "@angular/core";

import { LayoutFacade } from "@hermes/aphrodite/layout";
import { Context } from "@hermes/app-core";
import { LoaderSpinnerModule } from "@hermes/aphrodite/loader-spinner";

/**
 * This component implements the Hermes Action Button.
 * Not to be confused with the CTA button (h-call-to-action), which is the major, important button on a page.
 * Here we have an action button for editing a form or deleting informations, for example.
 *
 * Usage:
 * ```
 *  <h-action-button [size]="'medium'"
 *                   [name]="'button-name'"
 *                   [loading]="false"
 *                   [disabled]="false"
 *                   [doesDomButtonNeedToBeFocused]="false"
 *                   [type]="'button'"
 *                   [id]="'button-id'"
 *                   [describedBy]="'button description'"
 *                   [labelledBy]="'button-label'"
 *                   (pressed)="pressed()"
 *                   [dataTestId]="'My Button'">
 *      <ng-container svgLeft>
 *          <h-svg-icon [name]="'my-svg-if-I-want-one-on-left'"></h-svg-icon>
 *      </ng-container>
 *      <ng-container i18n="@@my.key.translate">
 *          My Button
 *      </ng-container>
 *      <ng-container svgRight>
 *          <h-svg-icon [name]="'my-svg-if-I-want-one-on-right'"></h-svg-icon>
 *      </ng-container>
 *  </h-action-button>
 * ```
 *
 */

export const ACTION_BUTTON_SIZE_SMALL = "small";
export const ACTION_BUTTON_SIZE_MEDIUM = "medium";
export const ACTION_BUTTON_SIZE_LARGE = "large";
export type ActionButtonSize =
    | typeof ACTION_BUTTON_SIZE_SMALL
    | typeof ACTION_BUTTON_SIZE_MEDIUM
    | typeof ACTION_BUTTON_SIZE_LARGE;

export const ACTION_BUTTON_TYPE_BUTTON = "button";
export const ACTION_BUTTON_TYPE_SUBMIT = "submit";
export type HtmlAttributeType =
    | typeof ACTION_BUTTON_TYPE_BUTTON
    | typeof ACTION_BUTTON_TYPE_SUBMIT;

export const ACTION_BUTTON_SVG_LEFT = "svgLeft";
export const ACTION_BUTTON_SVG_RIGHT = "svgRight";
export const ACTION_BUTTON_SVG_CENTER = "svgCenter";
export type ActionButtonSvgSide =
    | typeof ACTION_BUTTON_SVG_LEFT
    | typeof ACTION_BUTTON_SVG_RIGHT
    | typeof ACTION_BUTTON_SVG_CENTER;

export const ACTION_BUTTON_SVG_PLUS = "plus";
export const ACTION_BUTTON_SVG_MINUS = "minus";
export type ActionButtonSvgIcon =
    | typeof ACTION_BUTTON_SVG_PLUS
    | typeof ACTION_BUTTON_SVG_MINUS;

export const ACTION_BUTTON_SVG_WHITE = "stroke:white";
export const ACTION_BUTTON_SVG_BLACK = "stroke:black";
export type ActionButtonSvgColor =
    | typeof ACTION_BUTTON_SVG_WHITE
    | typeof ACTION_BUTTON_SVG_BLACK;

@Component({
    selector: "h-action-button",
    templateUrl: "./action-button.component.html",
    styleUrls: ["./action-button.component.scss"],
    standalone: true,
    imports: [CommonModule, LoaderSpinnerModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionButtonComponent implements OnChanges {
    @Input()
    public type: HtmlAttributeType = ACTION_BUTTON_TYPE_BUTTON;

    @Input()
    public id?: string;

    @Input()
    public name?: string;

    @Input()
    public loading: boolean = false;

    @Input()
    public disabled: boolean = false;

    @Input()
    public describedBy?: string;

    @Input()
    public labelledBy?: string;

    @Input()
    public label?: string;

    /**
     * For automated tests
     */
    @Input()
    public dataTestId?: string;

    /**
     * When provided, the button will have aria attributes to open the tray
     *
     * @see aria-dialog pattern.
     */
    @Input()
    public trayTargetId?: string;

    @Input()
    public size: ActionButtonSize = ACTION_BUTTON_SIZE_SMALL;

    /**
     * Some callers of this component prefer to manage SVG sizes themselves
     */
    @Input()
    public enableSvgSizes: boolean = true;

    @Input()
    public bold?: boolean = false;

    @Input()
    public doesDomButtonNeedToBeFocused: boolean = false;

    @Output()
    public pressed: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild("actionButton")
    public actionButton!: ElementRef<HTMLButtonElement>;

    constructor(private context: Context, public layoutFacade: LayoutFacade) {}

    public ngOnChanges(): void {
        if (
            this.context.isInBrowserMode() &&
            this.doesDomButtonNeedToBeFocused &&
            this.actionButton
        ) {
            this.actionButton.nativeElement.focus();
        }
    }

    /**
     * Prevent a click when the button is disabled with aria-disable
     * why is aria-disable better than disabled? Because the button can still be
     * focusable and read by the screen reader
     *
     *
     * @param event Event
     */
    public stopPropagation(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
    }
}

<form
    [attr.role]="'search'"
    class="header-search-form"
    [action]="formAction"
    method="get"
    i18n-method
    [class.expanded]="isFocused"
    [formGroup]="searchForm"
    (ngSubmit)="onSubmit()">
    <div
        class="search-input-container"
        [attr.role]="'combobox'"
        [attr.aria-haspopup]="'listbox'"
        [attr.aria-owns]="'header-search-suggestions'"
        [attr.aria-expanded]="!!suggestions">
        <h-svg-icon [name]="'search'" (click)="handleSearch()" class="search-icon" [class.focused]="isFocused">
        </h-svg-icon>
        <label class="sr-only" [attr.for]="'search-suggestion-field'" i18n="@@block--hermes-commerce--nav.search"
            >Search</label
        >
        <input
            #searchInput
            type="text"
            id="search-suggestion-field"
            name="s"
            class="search-input"
            title="Search for an article"
            i18n-title="@@block--hermes-commerce--nav.search-title"
            [attr.autocomplete]="'off'"
            [class.focused]="isFocused"
            placeholder="Search"
            i18n-placeholder="Search@@block--hermes-commerce--nav.search"
            formControlName="search"
            [attr.aria-autocomplete]="'list'"
            [attr.aria-controls]="'header-search-suggestions'"
            [attr.aria-activedescendant]="selected | suggestionItem"
            (focus)="handleFocus()"
            (blur)="handleBlur()"
            (keydown.esc)="handleEsc()"
            (keydown.arrowdown)="handleDown($event)"
            (keydown.arrowup)="handleUp($event)"
            (keydown.enter)="handleEnter($event)" />
        <h-svg-icon
            [name]="'cross'"
            (mousedown)="resetSearchField()"
            [class.focused]="isFocused"
            class="close-icon"></h-svg-icon>
    </div>
    <h-header-search-suggestions
        class="search-results"
        [class.hidden]="!suggestions"
        [suggestions]="suggestions"
        [searchTerm]="searchTerm"
        [selected]="selected">
    </h-header-search-suggestions>
</form>

// Payment methods codes
export const LEGACY_ALIPAY = "alipay_payment";
export const LEGACY_CUP = "hsbc_cup";
export const LEGACY_WECHAT = "wechatpay";
export const MERCURY_ADYEN_APPLE_PAY = "mercury_adyen_apple_pay";
export const MERCURY_ADYEN_CREDITCARD = "mercury_adyen_cc";
export const MERCURY_ADYEN_KCP_CC = "mercury_hpp_adyen_kcp_creditcard";
export const MERCURY_ADYEN_KCP_NAVERPAY = "mercury_hpp_adyen_kcp_naverpay";
export const MERCURY_ADYEN_KCP_PAYCO = "mercury_hpp_adyen_kcp_payco";
export const MERCURY_ADYEN_PAYPAL = "mercury_hpp_adyen_paypal";
export const MERCURY_ADYEN_PIX = "mercury_hpp_adyen_pix";
export const MERCURY_ADYEN_SAVEDCARD = "mercury_adyen_saved_card";
export const MERCURY_ALIPAY = "mercury_alipay";
export const MERCURY_CHECKOUTCOM_CC = "mercury_checkout_com_cc";
export const MERCURY_CHECKOUTCOM_ALIPAY = "checkout_com_alipay";
export const MERCURY_PAYPAL = "mercury_paypal";
export const MERCURY_WECHAT = "mercury_wechat";
export const MERCURY_ADYEN_ONE_CLICK = "mercury_adyen_one_click";
export const STRIPE_CC = "stripe_cc";

// Redirection consts
export const QRCODE_VALIDATION_WECHAT = "wechatpay";
export const QRCODE_VALIDATION_PIX = "pix";

export const PROCESSING_CHANNEL_PRODUCT = "product";
export const PROCESSING_CHANNEL_CART = "cart";
export const PROCESSING_CHANNEL_CHECKOUT = "checkout";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { ProductUtilsServiceModule } from "@hermes/fragments/product-utils";

import { ProductPersonalizationEffects } from "./effects/product-personalization.effects";
import { ProductPersonalizationFacade } from "./facades/product-personalization.facade";
import { UrlProductPersoHelper } from "./helpers/product-personalization.helper";
import {
    personalizationPageReducer,
    personalizationFeatureKey,
} from "./reducers/product-personalization.reducer";
import { FetchPlatformeConfigurationService } from "./services/fetch-platforme-configutation.service";
import { FetchPersonalizeProductService } from "./services/fetchPersonalizeProductService/fetch-personalize-product.service";
import { FetchPlatformeExtraInformationsService } from "./services/fetchPlatformeExtraInformationsService/fetch-platforme-extra-informations.service";
import { BeltkitMapPlatformeResponseService } from "./services/mapPlatformeResponseServices/beltkit-map-platforme-response.service";
import { MapPlatformeResponseService } from "./services/mapPlatformeResponseServices/map-platforme-response.service";
import { SmlgMapPlatformeResponseService } from "./services/mapPlatformeResponseServices/smlg-map-platforme-response.service";
import { PlatformeUrlService } from "./services/platforme-url/platforme-url.service";
import { PlatformeDkuService } from "./services/platformeDkuService/platforme-dku.service";
import { RipeInstanceService } from "./services/ripeInstanceService/ripe-instance.service";

@NgModule({
    imports: [
        CommonModule,
        EffectsModule.forFeature([ProductPersonalizationEffects]),
        StoreModule.forFeature(
            personalizationFeatureKey,
            personalizationPageReducer,
        ),
        ProductUtilsServiceModule,
    ],
    providers: [
        ProductPersonalizationFacade,
        UrlProductPersoHelper,
        FetchPlatformeConfigurationService,
        FetchPersonalizeProductService,
        BeltkitMapPlatformeResponseService,
        SmlgMapPlatformeResponseService,
        MapPlatformeResponseService,
        PlatformeUrlService,
        FetchPlatformeExtraInformationsService,
        RipeInstanceService,
        PlatformeDkuService,
    ],
})
export class PersonalizationPageStateModule {}

import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

import {
    hideLoader,
    hideNotification,
    keyboardNavigation,
    mouseNavigation,
    setHeaderTransparency,
    showLoader,
    showNotification,
    updateHeaderProperties as updateHeaderProperties,
} from "../actions/layout.actions";
import { IHeaderMainContainerProperties } from "../model/header-properties.model";
import { MessageOption } from "../model/notification.model";
import { State } from "../reducers/layout.reducer";
import {
    selectDisplayOverlay,
    selectHeader,
    selectHeaderAndBannerHeight,
    selectHeaderHeight,
    selectHeaderHeightWithLinks,
    selectHeaderTransparency,
    selectKeyboardNavigation,
    selectLoaderState,
    selectNotificationMessage,
} from "../selectors/layout.selectors";

@Injectable()
export class LayoutFacade implements OnDestroy {
    public headerMainHeight$ = this.store.select(selectHeaderHeight);
    public headerHeightWithLinks$ = this.store.select(
        selectHeaderHeightWithLinks,
    );
    public headerAndBannerHeight$ = this.store.select(
        selectHeaderAndBannerHeight,
    );
    public headerProperties$ = this.store.select(selectHeader);
    public headerTransparent$ = this.store.select(selectHeaderTransparency);
    public notificationMessage$ = this.store.select(selectNotificationMessage);
    public displayOverlay$ = this.store.select(selectDisplayOverlay);
    public keyboardNavigation$ = this.store.select(selectKeyboardNavigation);
    public selectLoaderState$ = this.store.select(selectLoaderState);

    private subscription = new Subscription();

    constructor(private store: Store<State>) {}

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public updateHeaderProperties(
        header: IHeaderMainContainerProperties,
    ): void {
        this.store.dispatch(updateHeaderProperties({ header }));
    }

    public setHeaderTransparency(isTransparent: boolean): void {
        this.store.dispatch(setHeaderTransparency({ isTransparent }));
    }

    public showLoader(): void {
        this.store.dispatch(showLoader());
    }

    public hideLoader(): void {
        this.store.dispatch(hideLoader());
    }

    public showNotification(
        displayOverlay: boolean,
        messageOption: MessageOption,
    ): void {
        this.store.dispatch(
            showNotification({
                displayOverlay,
                messageOption,
            }),
        );
    }

    public hideNotification(): void {
        this.store.dispatch(hideNotification());
    }

    public enableKeyboardNavigation(): void {
        this.store.dispatch(keyboardNavigation());
    }

    public enableMouseNavigation(): void {
        this.store.dispatch(mouseNavigation());
    }
}

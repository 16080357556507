import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { catchError, map, Observable, of } from "rxjs";

import { COUNTRY, LOCALE, Settings } from "@hermes/app-core";
import { Country, isUsa, Locale } from "@hermes/locale";

@Injectable()
export class ProductService {
    constructor(
        @Inject(COUNTRY) public country: Country,
        @Inject(LOCALE) private locale: Locale,
        private settings: Settings,
        private http: HttpClient,
    ) {}

    public getProp65materials(skus: string[]): Observable<string[]> {
        if (!isUsa(this.locale)) {
            return of([]);
        }

        return this.http
            .get<{ [sku: string]: string }>(
                `${this.settings.apiUrl}/get-prop65-dangerous-materials`,
                {
                    headers: new HttpHeaders(),
                    params: new HttpParams()
                        .set("skus", skus.join(","))
                        .set("locale", this.locale.code),
                },
            )
            .pipe(
                map((data: { [sku: string]: string }) => [
                    ...new Set(Object.values(data)),
                ]),
                catchError(() => {
                    throw new Error(
                        "get prop65 dangerous materials response error",
                    );
                }),
            );
    }
}

<div
    class="parent-category"
    [class.active]="isActive()"
    (mouseenter)="onMouseEnterParentCategory()"
    (mouseleave)="onMouseLeaveParentCategory()">
    <button
        #menuEntryEl
        *ngIf="isMenuCategory; else menuLink"
        class="category-link"
        [class.active]="isActive()"
        role="menuitem"
        aria-haspopup="true"
        [attr.aria-controls]="'sub-menu-' + menuEntry.name"
        [attr.aria-expanded]="isActive()"
        >{{ menuEntry.name }}</button
    >
    <div
        *ngIf="isMenuCategory"
        #subMenu
        [id]="'sub-menu-' + menuEntry.name"
        class="sub-menu"
        [class.show]="isActive()"
        aria-haspopup
        >{{ menuEntry.name }}</div
    >
</div>

<ng-template #menuLink>
    <a
        #menuEntryEl
        [href]="menuEntry.path"
        (click)="clickOnLink($event)"
        class="menu-link"
        [class.active]="isActive()"
        role="menuitem"
        >{{ menuEntry.name }}</a
    >
</ng-template>

import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";

@Injectable()
export class ViewportService {
    private renderer: Renderer2;
    private readonly viewportMeta: HTMLMetaElement | null;
    constructor(
        @Inject(DOCUMENT) private document: Document,
        rendererFactory: RendererFactory2,
    ) {
        this.renderer = rendererFactory.createRenderer(
            undefined,
            undefined as never,
        );
        this.viewportMeta = this.document.querySelector("meta[name=viewport]");
        if (!this.viewportMeta) {
            this.viewportMeta = this.renderer.createElement("meta");
            this.renderer.setAttribute(this.viewportMeta, "name", "viewport");
            this.renderer.appendChild(this.document.head, this.viewportMeta);
        }
    }
    public setMaxScale(maxScale: number) {
        this.setViewportAttribute("maximum-scale", maxScale.toString());
    }
    public setUserScalable(scalable: boolean) {
        this.setViewportAttribute("user-scalable", scalable ? "1" : "0");
    }

    public removeMaxScale() {
        this.removeViewportAttribute("maximum-scale");
    }

    private setViewportAttribute(attribute: string, value: string) {
        const newContent = this.partsAttribute(attribute);
        newContent.push(`${attribute}=${value}`);
        this.renderer.setAttribute(
            this.viewportMeta,
            "content",
            newContent.join(", "),
        );
    }
    private removeViewportAttribute(attribute: string) {
        const newContent = this.partsAttribute(attribute);
        this.renderer.setAttribute(
            this.viewportMeta,
            "content",
            newContent.join(", "),
        );
    }
    private partsAttribute(attribute: string) {
        const currentContent = this.viewportMeta?.getAttribute("content") || "";
        const contentParts = currentContent
            .split(",")
            .map((part) => part.trim());
        return contentParts.filter((part) => !part.startsWith(attribute));
    }
}

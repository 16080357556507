import { APP_BASE_HREF, Location } from "@angular/common";
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { merge } from "rxjs";
import { filter, map } from "rxjs/operators";

import { LOCALE } from "@hermes/app-core";
import { isCanada, isUsa, Locale } from "@hermes/locale";
import { AnalyticsService } from "@hermes/utils/analytics";
import {
    isEndOfNavigation,
    RouterService,
    urlWithoutFragment,
} from "@hermes/utils-generic/services/router";

import { HeaderClickEvent } from "../../events/header-click.event";

@Component({
    selector: "h-header-links",
    templateUrl: "./header-links.component.html",
    styleUrls: ["./header-links.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLinksComponent {
    @Input({ required: true })
    public isMenuHeaderActivated!: boolean;

    /**
     * Provide an observable to update skip links for both SSR and SPA routing :
     * - ActivatedRoute is used for initialisation (SSR)
     * - RouterEvent is then used when navigating inside the application (SPA routing)
     *
     * Trivia : ActivatedRoute is not updated after initialisation because current component is not inside a RouterOutlet.
     * RouterEvent fails to define initial value (unknown reason).
     */
    public skipLinks$ = merge(
        this.route.url.pipe(map(() => this.location.path())),
        this.router.events.pipe(
            filter(isEndOfNavigation),
            map(urlWithoutFragment),
        ),
    ).pipe(
        map((url: string) => ({
            content: this.anchorToSkipLink("#content", url),
            product: this.anchorToSkipLink("#collection-button", url),
            accessibility: this.getAccessibilityLink(),
        })),
    );

    public isCanada = isCanada(this.locale);
    public isUsa = isUsa(this.locale);
    public findAStoreUrl = `${this.locale.urlPrefix}/find-store/`;

    constructor(
        @Inject(APP_BASE_HREF) public baseHref: string,
        @Inject(LOCALE) private locale: Locale,
        private location: Location,
        private route: ActivatedRoute,
        private router: Router,
        private analyticsService: AnalyticsService,
        private routerService: RouterService,
    ) {}

    public sendHeaderEvent($event: Event, eventAction: string): void {
        $event.stopPropagation();
        this.analyticsService.sendData(
            new HeaderClickEvent({
                eventaction: eventAction,
            }),
        );
    }

    public navigateOnShopPage(
        $event: MouseEvent | KeyboardEvent,
        eventAction: string,
    ): void {
        $event.preventDefault();

        this.sendHeaderEvent($event, eventAction);

        this.routerService.navigate(this.findAStoreUrl);
    }

    public goToContactUsPage(event: MouseEvent): void {
        event.preventDefault();
        this.routerService.spaRedirect("/contact-us/");
    }

    private anchorToSkipLink(anchor: string, url: string): string {
        return `${this.locale.urlPrefix}${url}${anchor}`;
    }

    private getAccessibilityLink(): string {
        if (isCanada(this.locale)) {
            return `http://accessibility.hermes.com${this.locale.urlPrefix}/`;
        }
        return "http://accessibility.hermes.com";
    }
}

import { APP_BASE_HREF, CommonModule, Location } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import {
    APP_ID,
    APP_INITIALIZER,
    ErrorHandler,
    LOCALE_ID,
    NgModule,
} from "@angular/core";

import {
    BrowserModule,
    provideClientHydration,
} from "@angular/platform-browser";
import { ROUTES, UrlSerializer } from "@angular/router";

import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { TranslateService } from "@ngx-translate/core";

import { CookieService } from "ngx-cookie-service";

import { LayoutStateModule } from "@hermes/aphrodite/layout";
import { LoaderModule } from "@hermes/aphrodite/loader";
import { CurrencyModule } from "@hermes/aphrodite/price";
import {
    svgArrowDown,
    svgArrowLeft,
    svgArrowRight,
    svgBackCurvedArrow,
    svgCart,
    svgCrossRounded,
    svgExclamation,
    svgInfo,
    svgMinus,
    SvgModule,
    svgPlus,
    svgRoundBarGrey,
} from "@hermes/aphrodite/svg";
import {
    AppCoreModule,
    Context,
    COUNTRY,
    ENV_CONFIG,
    INFRA_MAP,
    INFRA_SETTINGS,
    infraSettingsFactory,
    LANG,
    LOCALE,
    WINDOW_PROVIDERS,
} from "@hermes/app-core";
import { EnvironmentConfiguration } from "@hermes/env-infra";
import { CartErrorInterceptorModule } from "@hermes/fragments/cart-error-interceptor";
import { ModalModule } from "@hermes/fragments/modal";
import { ShellModule } from "@hermes/fragments/shell";
import {
    CreateUserSessionOn401UnauthorizedInterceptor,
    HttpErrorInterceptor,
    InterceptorService,
    LocaleInterceptor,
    RedirectOn404NotFoundInterceptor,
    RedirectOn410GoneInterceptor,
    WithCredentialsInterceptor,
    XsrfInterceptor,
} from "@hermes/interceptors";
import {
    countryFromCode,
    langFromCode,
    Locale,
    localeFromId,
    localeFromUrlPrefix,
    LocaleId,
    LocaleUrlPrefix,
} from "@hermes/locale";
import { RiskifiedBeaconServiceModule } from "@hermes/riskified-beacon-service";
import { RouterComponentsModule } from "@hermes/router-components";
import { FeatureFlagFacade, FlipperStateModule } from "@hermes/states/flipper";
import { RouterStateModule } from "@hermes/states/router";
import { TRAY_MODULES, TrayStateModule } from "@hermes/states/tray";
import { UserStateModule } from "@hermes/states/user";
import {
    APOLLO_DEFAULT_INTERCEPTORS,
    APOLLO_INTERCEPTORS,
} from "@hermes/utils/constants";
import { LoadExternalUrlStateModule } from "@hermes/utils/services/load-external-url";
import { ApolloErrorHandler, CustomUrlSerializer } from "@hermes/web-custom";
import { LOGGER, LoggerService } from "@hermes/web-logger";
import { WEB_VITALS_ENVIRONMENT } from "@hermes/web-vitals";

import { trays } from "app/trays/trays.declaration";

import { initializeApplication } from "./app-initializer";
import { AppRoutingModule, interceptors } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { metaReducersFactory } from "./debug.reducer";
import { DrupalTrayServiceModule } from "./trays/drupal-tray-service/drupal-tray-service.module";

import { environment } from "environments/environment";
/**
 * Main module for Apollo application
 * -> It use Context to define APP_BASE_URL
 */
@NgModule({
    declarations: [AppComponent],
    imports: [
        // angular imports
        BrowserModule,
        CommonModule,
        HttpClientModule,

        // internal imports
        AppCoreModule,
        AppRoutingModule,
        CurrencyModule,
        FlipperStateModule,
        LayoutStateModule,
        LoaderModule,
        ModalModule,
        RiskifiedBeaconServiceModule,
        RouterStateModule,
        RouterComponentsModule,
        ShellModule,
        TrayStateModule.forRoot(),
        DrupalTrayServiceModule,
        UserStateModule,
        LoadExternalUrlStateModule.forRoot(),
        CartErrorInterceptorModule,
        SvgModule.forRoot([
            svgPlus,
            svgMinus,
            svgArrowRight,
            svgArrowLeft,
            svgArrowDown,
            svgBackCurvedArrow,
            svgCart,
            svgRoundBarGrey,
            svgCrossRounded,
            svgExclamation,
            svgInfo,
        ]),
        EffectsModule.forRoot(),
        StoreModule.forRoot([], {
            runtimeChecks: {
                strictStateImmutability: environment.ngrx.runtimeChecks,
                strictActionImmutability: environment.ngrx.runtimeChecks,
                // Serializability checks disabled as model-frontoffice is not serializable for now
                strictStateSerializability: false,
                strictActionSerializability: false,
                strictActionWithinNgZone: environment.ngrx.runtimeChecks,
                strictActionTypeUniqueness: environment.ngrx.runtimeChecks,
            },
            metaReducers: metaReducersFactory({
                debug: environment.ngrx.debug,
            }),
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            connectInZone: true,
        }),
    ],
    providers: [
        { provide: APP_ID, useValue: "hermes" },
        ...WINDOW_PROVIDERS,
        CookieService,
        InterceptorService,
        {
            provide: INFRA_MAP,
            useFactory: (environmentConfig: EnvironmentConfiguration) =>
                environmentConfig.hosts,
            deps: [ENV_CONFIG],
        },
        {
            provide: INFRA_SETTINGS,
            useFactory: infraSettingsFactory,
            deps: [Context, INFRA_MAP],
        },
        {
            provide: LOCALE_ID,
            useFactory: (baseHref: LocaleUrlPrefix) =>
                localeFromUrlPrefix(baseHref).id,
            deps: [APP_BASE_HREF],
        },
        {
            provide: LOCALE,
            useFactory: (localeId: LocaleId) => localeFromId(localeId),
            deps: [LOCALE_ID],
        },
        {
            provide: LANG,
            useFactory: (locale: Locale) => langFromCode(locale.langCode),
            deps: [LOCALE],
        },
        {
            provide: COUNTRY,
            useFactory: (locale: Locale) => countryFromCode(locale.countryCode),
            deps: [LOCALE],
        },
        { provide: APOLLO_INTERCEPTORS, useValue: interceptors },
        {
            provide: APOLLO_DEFAULT_INTERCEPTORS,
            useValue: [
                CreateUserSessionOn401UnauthorizedInterceptor,
                RedirectOn404NotFoundInterceptor,
            ],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (service: InterceptorService) => () => service.init(),
            deps: [InterceptorService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApplication,
            deps: [FeatureFlagFacade, TranslateService, LOCALE],
            multi: true,
        },
        {
            provide: LOGGER,
            useClass: LoggerService,
        },
        {
            provide: ErrorHandler,
            useClass: ApolloErrorHandler,
        },
        {
            provide: UrlSerializer,
            useClass: CustomUrlSerializer,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: WithCredentialsInterceptor,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LocaleInterceptor,
            multi: true,
        },

        // HTTP response error interceptors
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RedirectOn404NotFoundInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RedirectOn410GoneInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CreateUserSessionOn401UnauthorizedInterceptor,
            multi: true,
        },

        // reference to the lazy-loaded tray modules
        { provide: TRAY_MODULES, useValue: trays },
        { provide: ROUTES, useValue: trays, multi: true },
        { provide: WEB_VITALS_ENVIRONMENT, useValue: environment.webVitals },
        provideClientHydration(),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

// patch angular to NOT rewrite url without trailing slash
Location.stripTrailingSlash = (url: string): string => url;

import { AddToCartEvent } from "@hermes/states/add-to-cart";
import { GTMEventProductClickData } from "@hermes/utils/analytics";

/**
 * This class is used for Apple Pay button click (requires ecommerce information)
 */
export class AddToCartApplePayEvent extends AddToCartEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction, eventcategory key and ecommerce key
     */
    public override formatForGTM(): GTMEventProductClickData {
        return {
            ...super.formatForGTM(),
            eventaction: "add to cart - apple pay",
            ecommerce: {
                currencyCode: this.eventData.currencyCode,
                add: {
                    products: [
                        this.getProductData(
                            this.eventData.product,
                            this.eventData.isProductEngraving,
                        ),
                    ],
                },
            },
        };
    }
}

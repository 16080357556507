import { Injectable } from "@angular/core";
import type { Ripe } from "ripe-sdk/src/js";

import { PersonalizationAsset } from "@hermes/api-model-perso";

import {
    BELTKIT,
    personalizeConstants,
    SMALL_LEATHER_GOODS,
} from "../../constant/product-personalization.constant";
import {
    FacesMapping,
    MarkingPosition,
    PartType,
    ProductCategory,
} from "../../types/product-personalization.type";

/**
 * Concerns everything that has to do with the message to display on the product
 */
@Injectable({
    providedIn: "root",
})
export class PersoImageService {
    public ripe: Ripe;
    public facesMapping: FacesMapping = {
        exteriorFaces: [],
        interiorFaces: [],
    };
    public selectedFontMarkingZone: PartType = PartType.Interior;
    public productCategory: ProductCategory = "unsupported";

    /**
     * Initialization of the service
     */
    public initPersoImageService(
        ripeInstance: Ripe,
        productCategory: ProductCategory,
    ): void {
        this.ripe = ripeInstance;
        this.productCategory = productCategory;

        this.facesMapping.exteriorFaces =
            ripeInstance.loadedConfig.meta.exterior_faces;
        this.facesMapping.interiorFaces =
            ripeInstance.loadedConfig.meta.interior_faces;
    }

    public updateSelectedFontMarkingZone(
        selectedFontMarkingZone: PartType,
    ): void {
        this.selectedFontMarkingZone = selectedFontMarkingZone;
    }

    public getRequestAssets(face: MarkingPosition): PersonalizationAsset[] {
        const initials = this.getInitials(face, this.ripe.initials);
        return [
            {
                url: this.ripe._getImageURL({
                    brand: personalizeConstants.BRAND,
                    model: this.ripe.model,
                    frame: face,
                    crop: personalizeConstants.CROP,
                    format: personalizeConstants.FORMAT,
                    showInitials: true,
                    size: 850,
                    background: personalizeConstants.BACKGROUND,
                    initials,
                    engraving: this.ripe.engraving ?? "",
                    profile: [
                        this.ripe.engraving
                            ? this.ripe.engraving.replace(/\./g, ",")
                            : "",
                    ],
                }),
                face,
                customized: true,
            },
        ];
    }

    /**
     * Determine the message to display depending of the face and the product type
     *
     * @param face      The face of the image displayed
     * @param initials  The message to display on the image
     */
    public getInitials(face: MarkingPosition, initials: string): string {
        let displayInitials = true;

        switch (this.productCategory) {
            case BELTKIT: {
                displayInitials = [
                    MarkingPosition.zoom_back,
                    MarkingPosition.back,
                ].includes(face);
                break;
            }
            case SMALL_LEATHER_GOODS: {
                displayInitials =
                    this.selectedFontMarkingZone === this.getImageType(face);
                break;
            }
        }

        return displayInitials ? initials : "";
    }

    /**
     * Return the type of image (exterior or interior)
     */
    public getImageType(face: MarkingPosition): PartType {
        return this.facesMapping?.exteriorFaces?.includes(face)
            ? PartType.Exterior
            : PartType.Interior;
    }

    /**
     * Get the face by position
     */
    public getFaceByPosition(position: PartType): MarkingPosition | undefined {
        if (this.productCategory === SMALL_LEATHER_GOODS) {
            switch (position) {
                case PartType.Interior: {
                    return this.facesMapping
                        .interiorFaces[0] as MarkingPosition;
                }
                case PartType.Exterior: {
                    return this.facesMapping
                        .exteriorFaces[0] as MarkingPosition;
                }
                default: {
                    return undefined;
                }
            }
        }
        if (this.productCategory === BELTKIT) {
            return MarkingPosition.front;
        }
        return undefined;
    }
}

import {
    animate,
    state,
    style,
    transition,
    trigger,
} from "@angular/animations";
import { APP_BASE_HREF, Location } from "@angular/common";
import { ChangeDetectorRef, Component, Inject, Input } from "@angular/core";

import { toSignal } from "@angular/core/rxjs-interop";
import { map, Observable } from "rxjs";

import { LayoutFacade } from "@hermes/aphrodite/layout";
import { LOCALE } from "@hermes/app-core";
import { ACCOUNT_PATH, LOGIN_PATH } from "@hermes/fragments/customer";
import { Locale } from "@hermes/locale";
import { BasketFacade } from "@hermes/states/basket";
import {
    ACCOUNT_PAGE_ANGULAR,
    FeatureFlagFacade,
} from "@hermes/states/flipper";
import { UserFacade } from "@hermes/states/user";
import { AnalyticsService } from "@hermes/utils/analytics";
import { RouterService } from "@hermes/utils-generic/services/router";

import { BreakpointService } from "@hermes/utils-generic/services/user-interface";

import { HeaderAccountClickEvent } from "../../events/header-account-click.event";
import { HeaderClickEvent } from "../../events/header-click.event";

@Component({
    selector: "h-header-bar",
    templateUrl: "./header-bar.component.html",
    styleUrls: ["./header-bar.component.scss"],
    animations: [
        trigger("logoAnimation", [
            state("shown", style({ opacity: 1 })),
            state("hidden", style({ opacity: 0 })),
            transition("shown <=> hidden", [animate("200ms ease-in-out")]),
        ]),
    ],
})
export class HeaderBarComponent {
    @Input({ required: true })
    public isMenuHeaderActivated!: boolean;

    public showLogo: string = "shown";
    public totalItemsInBasket!: number;

    public accountUrl: string = ACCOUNT_PATH;
    public loginUrl: string = LOGIN_PATH;

    public isAccountPageAngularEnabled$: Observable<boolean> =
        this.featureFlagFacade.isActivated(ACCOUNT_PAGE_ANGULAR);

    public isLoggedIn$: Observable<boolean> = this.userFacade.isLoggedIn$;

    public totalItems$: Observable<number> = this.basketFacade.totalItems$;

    public isMobile = toSignal(
        this.breakpointService
            .mediumBreakpointObserver()
            .pipe(map((value) => !value.matches)),
        { initialValue: false },
    );

    public isHeaderTransparent = toSignal(
        this.layoutFacade.headerTransparent$,
        { initialValue: false },
    );

    public previousTransparencyValue: boolean | undefined;

    constructor(
        @Inject(APP_BASE_HREF) public baseHref: string,
        @Inject(LOCALE) private locale: Locale,
        private cdr: ChangeDetectorRef,
        private analyticsService: AnalyticsService,
        public basketFacade: BasketFacade,
        private userFacade: UserFacade,
        private featureFlagFacade: FeatureFlagFacade,
        private routerService: RouterService,
        private location: Location,
        private breakpointService: BreakpointService,
        private layoutFacade: LayoutFacade,
    ) {}

    public goToCartAndSendEvent(label: string): void {
        this.sendTopNavigationEvent(label);
        this.goToCart();
    }

    public goToCart(): void {
        // Changed to route with or without SPA depending on feature flag activation
        this.routerService.spaRedirect(`${this.locale.urlPrefix}/cart/`);
    }

    public goToPage(event: MouseEvent, path: string): void {
        event.preventDefault();
        if (this.location.path() === path) {
            // If we already are on page, we must reload it, so we use nativeRedirect
            this.routerService.nativeRedirect(
                `${this.locale.urlPrefix}${path}`,
            );
            return;
        }
        // else we use spaRedirect
        this.routerService.spaRedirect(path);
    }

    public sendAccountEvent(): void {
        this.analyticsService.sendData(
            new HeaderAccountClickEvent({
                virtualpageuri: `${this.locale.urlPrefix}/account`,
            }),
        );
    }

    public sendTopNavigationEvent(label: string): void {
        this.analyticsService.sendData(
            new HeaderClickEvent({
                eventaction: "TopNavigationHead",
                eventlabel: label,
            }),
        );
    }

    public onLogoClick(): void {
        this.analyticsService.sendData(
            new HeaderClickEvent({
                eventaction: "Logo",
            }),
        );
    }

    public onSearchExpanded(searchExpanded: boolean) {
        this.toggleLogo(searchExpanded);
        this.setTransparencyHeader(searchExpanded);
    }

    public toggleLogo(searchExpanded: boolean): void {
        this.showLogo = searchExpanded && this.isMobile() ? "hidden" : "shown";
        this.cdr.detectChanges();
    }

    public setTransparencyHeader(searchExpanded: boolean): void {
        if (searchExpanded) {
            if (this.isHeaderTransparent()) {
                this.previousTransparencyValue = true;
                this.layoutFacade.setHeaderTransparency(false);
            }
        } else {
            if (this.previousTransparencyValue) {
                this.previousTransparencyValue = undefined;
                this.layoutFacade.setHeaderTransparency(true);
            }
        }
        this.cdr.detectChanges();
    }
}

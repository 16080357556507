import { Location } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Ripe } from "ripe-sdk/src/js";
import { from, of, take } from "rxjs";
import { filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";

import { NotificationsService } from "@hermes/aphrodite/layout";
import { CurrencyService } from "@hermes/aphrodite/price";
import { ModelObject } from "@hermes/api-model-core";
import { Product } from "@hermes/api-model-product";
import { Context } from "@hermes/app-core";
import { ProductUtilsService } from "@hermes/fragments/product-utils";
import { Logger } from "@hermes/logger";
import { addToCartSuccess } from "@hermes/states/basket";
import { AnalyticsService } from "@hermes/utils/analytics";
import { LOGGER } from "@hermes/web-logger";

import {
    applyStateConfiguration,
    checkBeforeInitializationOfRipeInstance,
    fetchPersonalizeProducts,
    fetchPersonalizeProductsFailure,
    fetchPersonalizeProductsSuccess,
    fetchPlatformeConfiguration,
    fetchPlatformeConfigurationSuccess,
    handleFirstProductMissing,
    hideLoader,
    initializationOfRipeInstance,
    initiateBeltkitConfiguratorMapping,
    initiateConfiguratorMapping,
    initiateSmlgConfiguratorMapping,
    updateConfiguration,
    updateConfigurator,
    updateCurrentUrl,
    updateDisplayedUrl,
    updateSeveralConfiguration,
} from "../actions/product-personalization.actions";
import {
    BELTKIT,
    BELTKITS_EXPANDS,
    ERROR_PAGE_404_PATH,
    LOADED,
    LOADING,
    NO_RIPE,
    PERSO_LANDING_PAGE_PATH,
    PRODUCT_LEATHER_CATEGORY,
    SMALLLEATHERGOODS_EXPANDS,
    SMALL_LEATHER_GOODS,
} from "../constant/product-personalization.constant";
import { AddToCartPersoEvent } from "../events/add-to-cart-perso.event";
import { ProductPersonalizationFacade } from "../facades/product-personalization.facade";
import {
    buildProductPersoUrl,
    formatSku,
    getRadioButtonModelElement,
} from "../mappers/product-personalization.mapper";
import { FetchPlatformeConfigurationService } from "../services/fetch-platforme-configutation.service";
import { FetchPersonalizeProductService } from "../services/fetchPersonalizeProductService/fetch-personalize-product.service";
import { FetchPlatformeExtraInformationsService } from "../services/fetchPlatformeExtraInformationsService/fetch-platforme-extra-informations.service";
import { BeltkitMapPlatformeResponseService } from "../services/mapPlatformeResponseServices/beltkit-map-platforme-response.service";
import { SmlgMapPlatformeResponseService } from "../services/mapPlatformeResponseServices/smlg-map-platforme-response.service";
import { PersoImageService } from "../services/persoImageService/perso-image.service";
import { RipeInstanceService } from "../services/ripeInstanceService/ripe-instance.service";
import { InitPlatformeService } from "../services/ripeService/init-platforme.service";
import {
    PlatformeInitialConfiguration,
    BeltkitsConfigurator,
    NO_CONFIGURATION,
    RadioButtonModel,
    RadioButtonType,
    SmallLeatherGoodsConfigurator,
    ConfigPartsSmlg,
    MappedBeltkitConfigurator,
    MappedSmlgConfigurator,
    PlatformeConfigResponse,
    ConfigParts,
    Expend,
} from "../types/product-personalization.type";

import { UrlProductPersoHelper } from "./../helpers/product-personalization.helper";

/**
 * Effects on product page to call services or external API
 */
@Injectable()
export class ProductPersonalizationEffects {
    public addToCartSuccessAnalytics$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addToCartSuccess),
                withLatestFrom(
                    this.productPersonalizationFacade.parentProduct$,
                ),
                tap(([action, product]) => {
                    const currencyCode =
                        this.currencyService.getCurrencyMap()?.[1];
                    if (product && currencyCode) {
                        this.analyticsService.sendData(
                            new AddToCartPersoEvent({
                                currencyCode,
                                product,
                                quantity: 1,
                                context: action.context,
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    public updateDisplayedUrl$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateDisplayedUrl),
            withLatestFrom(
                this.productPersonalizationFacade.urlParsedParameters$,
                this.productPersonalizationFacade.productCategory$,
            ),
            map(([_action, urlParsedParameters, productCategory]) => {
                const builtUrl = buildProductPersoUrl(
                    productCategory,
                    urlParsedParameters,
                );
                const currentUrl = this.urlProductPersoHelper.getCurrentUrl(
                    productCategory,
                    urlParsedParameters,
                );
                this.location.replaceState(builtUrl);
                return updateCurrentUrl({ currentUrl });
            }),
        ),
    );

    public fetchPlatformeConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchPlatformeConfiguration),
            // only init ripe-sdk in a browser mode because Ripe use Document api
            filter(() => this.context.isInBrowserMode()),
            switchMap((action) =>
                this.fetchPlatformeConfigurationService.fetchPlatformeConfiguration(
                    action.productSku,
                ),
            ),
            map((platformeConfiguration: PlatformeInitialConfiguration) =>
                fetchPlatformeConfigurationSuccess({
                    platformeConfiguration,
                }),
            ),
        ),
    );

    public fetchPersonalizeProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchPersonalizeProducts),
            switchMap((action) => {
                const { skus, productCategory } = action;
                this.productPersonalizationFacade.updateLoadingProgress(
                    LOADING,
                );

                // Remove duplicated sku
                const productSkus = [...new Set(skus)];

                return this.fetchPersonalizeProductService
                    .fetchPersonalizeProductWithSkus(productSkus)
                    .pipe(
                        map((response) => {
                            if ("error" in response) {
                                return fetchPersonalizeProductsFailure({
                                    error: response.error,
                                    path: ERROR_PAGE_404_PATH,
                                });
                            }

                            this.productPersonalizationFacade.updateIsDacProductMissing(
                                productSkus.length !== response.length,
                            );

                            // fix order products response for beltkits to have the buckle first
                            // productSkus: leatherSku then buckleSku (defined in base-component-beltkits.component)
                            // no family data in product_customizable table => not possible to do in bck without changing the query
                            if (productCategory === BELTKIT) {
                                this.fetchPersonalizeProductService.reorderBeltkitsProducts(
                                    response,
                                    productSkus,
                                );
                            }

                            return fetchPersonalizeProductsSuccess({
                                products: response,
                            });
                        }),
                    );
            }),
        ),
    );

    public fetchPersonalizeProductsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchPersonalizeProductsSuccess),
            withLatestFrom(
                this.productPersonalizationFacade.urlParsedParameters$,
                this.productPersonalizationFacade.productCategory$,
            ),
            tap(([action, urlParsedParameters, productCategory]) => {
                const responseMapped = action.products.map((reponseItem) =>
                    ModelObject.fromJsonData(reponseItem, Product),
                );

                this.fetchPersonalizeProductService.updatePersoProducts(
                    responseMapped,
                    productCategory,
                    urlParsedParameters,
                );
            }),
            filter(
                ([action, urlParsedParameters, _productCategory]) =>
                    !action.products.some(
                        (product) =>
                            product.sku === urlParsedParameters.firstSku,
                    ),
            ),
            map(([_action, _urlParsedParameters, _productCategory]) =>
                handleFirstProductMissing(),
            ),
        ),
    );

    /**
     * Allows to manage the error case when the number of results is not consistent with the number of skus
     * LEATHER:
     * - redirect to PersoLandingPagePath:  if the first or the second sku is not found
     */
    public handleFirstProductMissing$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(handleFirstProductMissing),
                withLatestFrom(
                    this.productPersonalizationFacade.urlParsedParameters$,
                    this.productPersonalizationFacade.firstProduct$,
                    this.productPersonalizationFacade.secondProduct$,
                    this.productPersonalizationFacade.productCategory$,
                ),
                tap(
                    ([
                        _action,
                        urlParsedParameters,
                        firstProduct,
                        secondProduct,
                        productCategory,
                    ]) => {
                        // Leather cases
                        if (
                            PRODUCT_LEATHER_CATEGORY.includes(
                                productCategory,
                            ) &&
                            (!firstProduct || !secondProduct)
                        ) {
                            this.notificationsService.hideLoader();
                            this.fetchPersonalizeProductService.navigateTo(
                                PERSO_LANDING_PAGE_PATH,
                                urlParsedParameters.originalUrl,
                            );
                        }
                    },
                ),
            ),
        { dispatch: false },
    );

    public fetchPersonalizeProductsFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fetchPersonalizeProductsFailure),
                withLatestFrom(
                    this.productPersonalizationFacade.urlParsedParameters$,
                ),
                tap(([action, urlParsedParameters]) => {
                    this.logger.error(
                        `[FetchPersonalizeProduct] :${action.error}`,
                    );
                    this.productPersonalizationFacade.updateLoadingProgress(
                        LOADED,
                    );
                    this.notificationsService.hideLoader();
                    this.fetchPersonalizeProductService.navigateTo(
                        action.path,
                        urlParsedParameters.originalUrl,
                    );
                }),
            ),
        { dispatch: false },
    );

    // Check if PlatformE inital configuration is needed and, if so, is present to launch Ripe initialization.
    public checkBeforeInitializationOfRipeInstance$ = createEffect(() =>
        this.actions$.pipe(
            ofType(checkBeforeInitializationOfRipeInstance),
            withLatestFrom(
                this.productPersonalizationFacade.urlParsedParameters$,
            ),
            switchMap(([_action, urlParsedParameters]) => {
                if (urlParsedParameters.requireConfCall) {
                    return this.productPersonalizationFacade.platformeInitialConfiguration$.pipe(
                        filter(
                            (
                                platformeInitialConfiguration:
                                    | PlatformeInitialConfiguration
                                    | typeof NO_CONFIGURATION,
                            ): platformeInitialConfiguration is PlatformeInitialConfiguration =>
                                platformeInitialConfiguration !==
                                NO_CONFIGURATION,
                        ),
                    );
                }
                return of(true);
            }),
            map(() =>
                initializationOfRipeInstance({ initiateConfigurator: true }),
            ),
        ),
    );

    // Actually initialize Ripe object and propagate its instance to subscribers.
    // Also in charge of PlatformeConfigResponse spreading
    public initializationOfRipeInstance$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(initializationOfRipeInstance),
                withLatestFrom(
                    this.productPersonalizationFacade.parentProduct$,
                    this.productPersonalizationFacade
                        .platformeInitialConfiguration$,
                    this.productPersonalizationFacade.urlParsedParameters$,
                    this.productPersonalizationFacade.productCategory$,
                ),
                tap(
                    ([
                        action,
                        parentProduct,
                        platformeInitialConfiguration,
                        urlParsedParameters,
                        productCategory,
                    ]) => {
                        const encodedDku =
                            this.initPlatformeService.fetchInitialDkuForPlatforme(
                                urlParsedParameters,
                                platformeInitialConfiguration,
                                productCategory,
                            );
                        this.productPersonalizationFacade.updateDku(encodedDku);
                        this.productPersonalizationFacade.updateDisplayedUrl();
                        // Model parameter is why we must wait for PlatformeInitialConfiguration sometimes.
                        const model =
                            platformeInitialConfiguration !==
                                NO_CONFIGURATION &&
                            platformeInitialConfiguration.model
                                ? platformeInitialConfiguration.model
                                : formatSku(parentProduct.sku).toLowerCase();
                        this.initPlatformeService.initPlatformeSdk(
                            model,
                            encodedDku ?? "",
                        );

                        // Case false : coming back to same perso page, we need another Ripe instance, not all configurator mapping again.
                        if (action.initiateConfigurator) {
                            this.productPersonalizationFacade.initiateConfiguratorConstruction();
                        }
                    },
                ),
            ),
        { dispatch: false },
    );

    // Case not same page return -> we must fetch all data.
    public initiateConfiguratorMapping$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(initiateConfiguratorMapping),
                switchMap(
                    () =>
                        this.initPlatformeService.initiateConfiguratorMapping$,
                ),
                filter(
                    (
                        configuration:
                            | PlatformeConfigResponse
                            | typeof NO_CONFIGURATION,
                    ): configuration is PlatformeConfigResponse =>
                        configuration !== NO_CONFIGURATION,
                ),
                withLatestFrom(
                    this.productPersonalizationFacade.productCategory$,
                ),
                tap(([configuration, productCategory]) =>
                    this.productPersonalizationFacade.initiateConfiguratorMapping(
                        configuration,
                        productCategory,
                    ),
                ),
            ),
        { dispatch: false },
    );

    public initiatePersoImageService$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(applyStateConfiguration),
                withLatestFrom(
                    this.initPlatformeService.ripeInstance$.pipe(
                        filter(
                            (ripeInstance: Ripe | typeof NO_RIPE) =>
                                ripeInstance !== NO_RIPE,
                        ),
                    ),
                    this.productPersonalizationFacade.productCategory$,
                ),
                tap(([_action, ripeInstance, productCategory]) =>
                    this.persoImageService.initPersoImageService(
                        ripeInstance,
                        productCategory,
                    ),
                ),
            ),
        { dispatch: false },
    );

    public initiateBeltkitConfiguratorMapping$ = createEffect(() =>
        this.actions$.pipe(
            ofType(initiateBeltkitConfiguratorMapping),
            withLatestFrom(
                this.initPlatformeService.ripeInstance$.pipe(
                    filter(
                        (ripeInstance: Ripe | typeof NO_RIPE) =>
                            ripeInstance !== NO_RIPE,
                    ),
                ),
                this.productPersonalizationFacade.plateformeExtraInformation$,
            ),
            // If translations or color codes from Platforme missing from State, we fetch them
            switchMap(([action, ripeInstance, plateformeExtraInformation]) => {
                if (
                    Object.keys(plateformeExtraInformation.colorsHexaCodes)
                        .length === 0 ||
                    Object.keys(plateformeExtraInformation.translations)
                        .length === 0
                ) {
                    return from(
                        this.fetchPlatformeExtraInformationsService.getPFExtraData(
                            ripeInstance,
                        ),
                    ).pipe(
                        map((plateformeExtraInformationFetched) => [
                            action,
                            ripeInstance,
                            plateformeExtraInformationFetched,
                        ]),
                    );
                }
                return of([action, ripeInstance, plateformeExtraInformation]);
            }),
            map(([action, ripeInstance, plateformeExtraInformation]) => {
                const mappedBeltkitConfigurator: MappedBeltkitConfigurator =
                    this.beltkitMapPlatformeResponseService.initBeltkitConfigurator(
                        action.platformeConfigResponse,
                        ripeInstance,
                    );
                // convertMappedBeltkitConfiguratorToBeltkitsConfigurator is coming from previous deleted Service : beltkit-adapter.service.ts
                const beltkitsConfigurator: BeltkitsConfigurator =
                    this.beltkitMapPlatformeResponseService.convertMappedBeltkitConfiguratorToBeltkitsConfigurator(
                        mappedBeltkitConfigurator,
                    );
                const configurator =
                    this.fetchPlatformeExtraInformationsService.getConfiguratorUpdatedWithPFExtraData(
                        plateformeExtraInformation.colorsHexaCodes,
                        plateformeExtraInformation.translations,
                        beltkitsConfigurator,
                        BELTKIT,
                    );
                return updateConfigurator({ configurator });
            }),
        ),
    );

    public hideLoader$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(hideLoader),
                tap((_action) => {
                    this.productPersonalizationFacade.updateLoadingProgress(
                        LOADED,
                    );
                    this.notificationsService.hideLoader();
                }),
            ),
        { dispatch: false },
    );

    public initiateSmlgConfiguratorMapping$ = createEffect(() =>
        this.actions$.pipe(
            ofType(initiateSmlgConfiguratorMapping),
            withLatestFrom(
                this.initPlatformeService.ripeInstance$.pipe(
                    filter(
                        (ripeInstance: Ripe | typeof NO_RIPE) =>
                            ripeInstance !== NO_RIPE,
                    ),
                ),
                this.productPersonalizationFacade.plateformeExtraInformation$,
            ),
            // If translations or color codes from Platforme missing from State, we fetch them
            switchMap(([action, ripeInstance, plateformeExtraInformation]) => {
                if (
                    Object.keys(plateformeExtraInformation.colorsHexaCodes)
                        .length > 0 &&
                    Object.keys(plateformeExtraInformation.translations)
                        .length > 0
                ) {
                    return of([
                        action,
                        ripeInstance,
                        plateformeExtraInformation,
                    ]);
                }
                return from(
                    this.fetchPlatformeExtraInformationsService.getPFExtraData(
                        ripeInstance,
                    ),
                ).pipe(
                    map((plateformeExtraInformationFetched) => [
                        action,
                        ripeInstance,
                        plateformeExtraInformationFetched,
                    ]),
                );
            }),
            map(([action, ripeInstance, plateformeExtraInformation]) => {
                const mappedSmlgConfigurator: MappedSmlgConfigurator =
                    this.smlgMapPlatformeResponseService.initSmlgConfigurator(
                        action.platformeConfigResponse,
                        ripeInstance,
                    );
                // We must store leathers as we update color and leather configurator on user interaction
                this.productPersonalizationFacade.updateSmallLeatherGoodsLeathers(
                    mappedSmlgConfigurator.leather,
                );
                // convertMappedSmlgConfiguratorToSmallLeatherGoodsConfigurator is coming from previous deleted Service : smlg-adapter.service.ts
                const smallLeatherGoodsConfigurator: SmallLeatherGoodsConfigurator =
                    this.smlgMapPlatformeResponseService.convertMappedSmlgConfiguratorToSmallLeatherGoodsConfigurator(
                        mappedSmlgConfigurator,
                    );
                const configurator =
                    this.fetchPlatformeExtraInformationsService.getConfiguratorUpdatedWithPFExtraData(
                        plateformeExtraInformation.colorsHexaCodes,
                        plateformeExtraInformation.translations,
                        smallLeatherGoodsConfigurator,
                        SMALL_LEATHER_GOODS,
                    );
                return updateConfigurator({ configurator });
            }),
        ),
    );

    public updateRipeConfiguration$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    updateConfiguration,
                    updateSeveralConfiguration,
                    applyStateConfiguration,
                ),
                withLatestFrom(
                    this.productPersonalizationFacade.productCategory$,
                ),
                // Effect triggered too fast when returning to a perso page (SMLG ->  Grid -> Beltkit)
                // Must use a filter to avoid starting template errors. (Beltkit template with SMLG config...).
                switchMap(([_action, productCategory]) => {
                    let expandObject: Expend = SMALLLEATHERGOODS_EXPANDS;
                    if (productCategory === BELTKIT) {
                        expandObject = BELTKITS_EXPANDS;
                    }
                    return this.productPersonalizationFacade.configuration$.pipe(
                        filter(
                            (configuration: ConfigParts) =>
                                Object.keys(configuration).length ===
                                Object.keys(expandObject).length,
                        ),
                        take(1),
                    );
                }),
                withLatestFrom(
                    this.productPersonalizationFacade.productCategory$,
                    this.initPlatformeService.ripeInstance$.pipe(
                        filter(
                            (ripeInstance: Ripe | typeof NO_RIPE) =>
                                ripeInstance !== NO_RIPE,
                        ),
                    ),
                ),
                tap(([configuration, productCategory, ripeInstance]) => {
                    const engravingConfiguration =
                        this.ripeInstanceService.getEngravingConfiguration(
                            configuration,
                        );
                    this.productPersonalizationFacade.updateEngravingConfiguration(
                        engravingConfiguration,
                    );
                    this.ripeInstanceService.updatePartAndMessage(
                        configuration,
                        productCategory,
                        ripeInstance,
                    );
                }),
            ),
        { dispatch: false },
    );

    // In SMLG if user change firstSku (Leather), we must update leather and color option in configurator.
    public smlgApplyNewLeatherConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateConfiguration),
            withLatestFrom(
                this.productPersonalizationFacade.productCategory$,
                this.productPersonalizationFacade.configurator$,
                this.productPersonalizationFacade.configuration$,
                this.productPersonalizationFacade.smallLeatherGoodsLeathers$,
                this.productPersonalizationFacade.plateformeExtraInformation$,
            ),
            filter(
                ([
                    action,
                    productCategory,
                    _configurator,
                    _configuration,
                    _smallLeatherGoodsLeathers,
                    _plateformeExtraInformation,
                ]) =>
                    productCategory === SMALL_LEATHER_GOODS &&
                    action.configuration.id === "leather",
            ),
            map(
                ([
                    _action,
                    _productCategory,
                    configurator,
                    configuration,
                    smallLeatherGoodsLeathers,
                    plateformeExtraInformation,
                ]) => {
                    // If a leather has been chosen on configurator, it is the default item to choose.
                    const selectedLeather =
                        (configuration as ConfigPartsSmlg).leather?.value ??
                        "default";
                    // Leather combinations & colors
                    const leather: RadioButtonModel =
                        getRadioButtonModelElement(
                            true,
                            RadioButtonType.Text,
                            "leather",
                            this.smlgMapPlatformeResponseService.mapLeatherItem(
                                smallLeatherGoodsLeathers,
                                configurator,
                                selectedLeather,
                            ),
                            false,
                        );
                    const configuratorWithLeather = {
                        ...configurator,
                        leather,
                    };
                    const configuratorWithUpdatedColors =
                        this.fetchPlatformeExtraInformationsService.getConfiguratorUpdatedWithPFExtraData(
                            plateformeExtraInformation.colorsHexaCodes,
                            plateformeExtraInformation.translations,
                            configuratorWithLeather,
                            SMALL_LEATHER_GOODS,
                        );

                    return updateConfigurator({
                        configurator: configuratorWithUpdatedColors,
                    });
                },
            ),
        ),
    );

    public addToCartSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addToCartSuccess),
                withLatestFrom(this.productPersonalizationFacade.firstProduct$),
                filter(([payload]) => payload.displayNotifOnSuccess),
                tap(([payload, persoFirstProduct]) => {
                    if (persoFirstProduct) {
                        this.productUtilsService.openSuccessAddToCartModal(
                            payload,
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private productUtilsService: ProductUtilsService,
        private analyticsService: AnalyticsService,
        private currencyService: CurrencyService,
        private urlProductPersoHelper: UrlProductPersoHelper,
        private fetchPlatformeConfigurationService: FetchPlatformeConfigurationService,
        private fetchPersonalizeProductService: FetchPersonalizeProductService,
        private productPersonalizationFacade: ProductPersonalizationFacade,
        private notificationsService: NotificationsService,
        private initPlatformeService: InitPlatformeService,
        private beltkitMapPlatformeResponseService: BeltkitMapPlatformeResponseService,
        private smlgMapPlatformeResponseService: SmlgMapPlatformeResponseService,
        private fetchPlatformeExtraInformationsService: FetchPlatformeExtraInformationsService,
        private persoImageService: PersoImageService,
        private ripeInstanceService: RipeInstanceService,
        private context: Context,
        private readonly location: Location,
        @Inject(LOGGER) private logger: Logger,
    ) {}
}

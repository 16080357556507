import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    effect,
    inject,
    signal,
} from "@angular/core";

import { Observable, Subscription } from "rxjs";

import { MenuEntryItem } from "@hermes/states/menu";

import { RouterService } from "@hermes/utils-generic/services/router";

import { isMenuCategory } from "../../../helpers/menu-bar.helper";

@Component({
    selector: "h-menu-parent-category",
    templateUrl: "./menu-parent-category.component.html",
    styleUrls: ["./menu-parent-category.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuParentCategoryComponent implements OnInit, OnDestroy {
    @ViewChild("menuEntryEl")
    public menuEntryEl!: ElementRef;

    @Input({ required: true })
    public menuEntry!: MenuEntryItem;

    @Input({ required: true })
    public onCursorLeaveMenuBar$!: Observable<void>;

    public hoverEnterTimeout!: NodeJS.Timeout;
    public hoverLeaveTimeout!: NodeJS.Timeout;

    public isActive = signal(false);

    public isFocused = signal(false);

    public isMenuCategory!: boolean;

    public subscriptions: Subscription = new Subscription();

    public routerService = inject(RouterService);

    constructor() {
        effect(() => {
            if (this.isFocused()) {
                this.menuEntryEl.nativeElement.focus();
            }
        });
    }

    @Input({ required: true })
    public set focused(focused: boolean) {
        this.isFocused.set(focused);
    }

    public ngOnInit(): void {
        this.isMenuCategory = isMenuCategory(this.menuEntry);
        this.subscriptions.add(
            this.onCursorLeaveMenuBar$.subscribe(() => {
                this.isActive.set(false);
            }),
        );
    }

    public onMouseEnterParentCategory() {
        clearTimeout(this.hoverLeaveTimeout);
        this.hoverEnterTimeout = setTimeout(() => {
            this.isActive.set(true);
        }, 175);
    }

    public onMouseLeaveParentCategory() {
        clearTimeout(this.hoverEnterTimeout);
        this.hoverLeaveTimeout = setTimeout(() => {
            this.isActive.set(false);
        }, 300); // Timeout is greater on leave to not have the sub menu flickering from each category
    }

    public clickOnLink($event: Event) {
        $event.preventDefault();
        this.routerService.spaRedirect(this.menuEntry.path);
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}

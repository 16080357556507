import { Product } from "@hermes/api-model-product";
import {
    CartAnalyticsEvent,
    GTMEcommerceItemData,
    GTMEventProductClickData,
    HERMES_BRAND,
    NO,
    NODATA,
    NO_SIZE,
    PRODUCT_IN_STOCK,
    PRODUCT_OUT_OF_STOCK,
    YES,
    getStockTypeAndDisplayOnly,
    isProductEngravable,
} from "@hermes/utils/analytics";
import { ProductTemplateType } from "@hermes/utils/constants";
import { isOutOfStock } from "@hermes/utils/helpers";

/**
 * This class is used for add-to-cart event in cart page
 */
export class AddToCartEvent extends CartAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for ecommerce key
     */
    public override formatForGTM(): GTMEventProductClickData {
        return {
            ...super.formatForGTM(),
            ecommerce: {
                currencyCode: this.eventData.currencyCode,
                add: {
                    products: [
                        this.getProductData(
                            this.eventData.product,
                            this.eventData.isProductEngraving,
                            this.eventData.isProductEngravable,
                        ),
                    ],
                },
            },
        };
    }

    /**
     * Formatter for product
     *
     * @param product product clicked
     */
    protected getProductData = (
        product: Product,
        isProductEngraving: boolean,
        isProductEngravableFromCheckout: boolean = false,
    ): GTMEcommerceItemData => ({
        name: product.title,
        price: product.price ? product.price.toFixed(2) : "",
        brand: HERMES_BRAND,
        quantity: this.eventData.quantity,
        dimension10: NODATA,
        dimension37:
            product.stock &&
            isOutOfStock(product.stock) &&
            !this.eventData.isVtoProduct
                ? PRODUCT_OUT_OF_STOCK
                : PRODUCT_IN_STOCK,
        dimension52:
            isProductEngravable(product) ||
            isProductEngravableFromCheckout ||
            product.personalize
                ? YES
                : NO,
        dimension53: getStockTypeAndDisplayOnly(
            product.stock,
            product.displayMode,
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        availability_product: getStockTypeAndDisplayOnly(
            product.stock,
            product.displayMode,
        ),
        ...this.getFamilyAttributes(product, isProductEngraving),
    });

    private getFamilyAttributes(
        product: Product,
        isProductEngraving: boolean,
    ): GTMEcommerceItemData | undefined {
        switch (product.templateType || this.eventData.isVtoProduct) {
            case ProductTemplateType.PersoSMLG:
            case ProductTemplateType.PersoBeltkit:
            case this.eventData.isVtoProduct: {
                return {
                    id: product.sku,
                    category: `${product.departmentCode}/${product.familyCode}`,
                    variant: product.secondId || NODATA,
                    // eslint-disable-next-line unicorn/explicit-length-check
                    dimension5: product.size || NO_SIZE,
                    dimension6: product.departmentCode,
                    dimension7: product.familyCode,
                    dimension8: product.productCode,
                    dimension9: product.divisionCode,
                    dimension54: YES,
                    context: this.eventData.context,
                } as GTMEcommerceItemData;
            }

            case ProductTemplateType.Simple: {
                return {
                    id: product.sku,
                    category: `${product.departmentCode}/${product.familyCode}`,
                    variant:
                        product.simpleAttributes?.colorHermesCode || NODATA,
                    dimension5:
                        product.simpleAttributes?.modelSize ||
                        // eslint-disable-next-line unicorn/explicit-length-check
                        product.size ||
                        NODATA,
                    dimension6: product.departmentCode,
                    dimension7: product.familyCode,
                    dimension8: product.productCode,
                    dimension9: product.divisionCode,
                    dimension54: isProductEngraving ? YES : NO,
                    context: this.eventData.context,
                } as GTMEcommerceItemData;
            }

            case ProductTemplateType.Beltkit: {
                return {
                    id: `${product.beltkitAttributes?.skuBuckle}|${product.beltkitAttributes?.skuLeather}`,
                    category: `${product.beltkitAttributes?.departmentCodeLeather}|${product.beltkitAttributes?.departmentCodeBuckle}/${product.beltkitAttributes?.familyCodeLeather}|${product.beltkitAttributes?.familyCodeBuckle}`,
                    variant: `${product.beltkitAttributes?.colorHermesLeather}|${product.beltkitAttributes?.displayNameList} ${product.beltkitAttributes?.colorHermesBuckle}`,
                    dimension5: `${product.beltkitAttributes?.skuLeather.slice(
                        -3,
                    )}`,
                    dimension6: `${product.beltkitAttributes?.departmentCodeLeather}|${product.beltkitAttributes?.departmentCodeBuckle}`,
                    dimension7: `${product.beltkitAttributes?.familyCodeLeather}|${product.beltkitAttributes?.familyCodeBuckle}`,
                    dimension8: `${product.beltkitAttributes?.productCodeLeather}|${product.beltkitAttributes?.productCodeBuckle}`,
                    dimension9: `${product.beltkitAttributes?.divisionCodeLeather}|${product.beltkitAttributes?.divisionCodeBuckle}`,
                    context: this.eventData.context,
                } as GTMEcommerceItemData;
            }

            case ProductTemplateType.AppleWatch: {
                return {
                    id: `${product.appleWatchAttributes?.skuGuizmo}|${product.appleWatchAttributes?.skuStrap}`,
                    category: `${product.appleWatchAttributes?.departmentCodeGuizmo}|${product.appleWatchAttributes?.departmentCodeStrap}/${product.appleWatchAttributes?.familyCodeGuizmo}|${product.appleWatchAttributes?.familyCodeStrap}`,
                    variant: `${product.appleWatchAttributes?.colorHermesStrap}|${product.appleWatchAttributes?.finishGuizmo}`,
                    dimension5: `${product.appleWatchAttributes?.modelSizeGuizmo.slice(
                        -3,
                    )}`,
                    dimension6: `${product.appleWatchAttributes?.departmentCodeGuizmo}|${product.appleWatchAttributes?.departmentCodeStrap}`,
                    dimension7: `${product.appleWatchAttributes?.familyCodeGuizmo}|${product.appleWatchAttributes?.familyCodeStrap}`,
                    dimension8: `${product.appleWatchAttributes?.productCodeGuizmo}|${product.appleWatchAttributes?.productCodeStrap}`,
                    dimension9: `${product.appleWatchAttributes?.divisionCodeGuizmo}|${product.appleWatchAttributes?.divisionCodeStrap}`,
                    context: this.eventData.context,
                } as GTMEcommerceItemData;
            }

            case ProductTemplateType.DoubleFragrance: {
                return {
                    id: `${product.doubleFragranceAttributes?.skuPerfume}|${product.doubleFragranceAttributes?.skuAccessory}`,
                    category: `${product.doubleFragranceAttributes?.departmentCodePerfume}|${product.doubleFragranceAttributes?.departmentCodeAccessory}/${product.doubleFragranceAttributes?.familyCodePerfume}|${product.doubleFragranceAttributes?.familyCodeAccessory}`,
                    variant: NODATA,
                    dimension5: NO_SIZE,
                    dimension6: `${product.doubleFragranceAttributes?.departmentCodePerfume}|${product.doubleFragranceAttributes?.departmentCodeAccessory}`,
                    dimension7: `${product.doubleFragranceAttributes?.familyCodePerfume}|${product.doubleFragranceAttributes?.familyCodeAccessory}`,
                    dimension8: `${product.doubleFragranceAttributes?.productCodePerfume}|${product.doubleFragranceAttributes?.productCodeAccessory}`,
                    dimension9: `${product.doubleFragranceAttributes?.divisionCodePerfume}|${product.doubleFragranceAttributes?.divisionCodeAccessory}`,
                    context: this.eventData.context,
                } as GTMEcommerceItemData;
            }

            default: {
                return undefined;
            }
        }
    }
}

<button
    class="action-button-base action-button-size-{{ size }}"
    [class.disabled]="disabled || loading"
    [class.bold]="bold"
    [type]="type"
    [attr.id]="id"
    [name]="name"
    [class.keyboard-navigation-button]="layoutFacade.keyboardNavigation$ | async"
    [attr.aria-describedby]="describedBy ? describedBy : undefined"
    [attr.aria-labelledby]="labelledBy ? labelledBy : undefined"
    [attr.aria-disabled]="disabled || loading"
    [attr.disabled]="disabled || loading ? true : null"
    (click)="disabled || loading ? stopPropagation($event) : pressed.emit()"
    [attr.data-testid]="dataTestId"
    #actionButton>
    <ng-template #loader>
        <h-loader-spinner> </h-loader-spinner>
    </ng-template>

    <ng-template #content>
        <div [class.action-button-content-container]="enableSvgSizes">
            <span class="action-button-svg-left">
                <ng-content select="[svgLeft]"></ng-content>
            </span>
            <ng-content *ngIf="!loading"></ng-content>
            <span class="action-button-svg-right">
                <ng-content select="[svgRight]"></ng-content>
            </span>
        </div>
        <span [class.action-button-svg-center]="enableSvgSizes">
            <ng-content select="[svgCenter]"></ng-content>
        </span>
    </ng-template>

    <div *ngIf="loading; then loader; else content"></div>
</button>

import { BreakpointState } from "@angular/cdk/layout";
import { DOCUMENT } from "@angular/common";
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    NgZone,
    OnDestroy,
    ViewEncapsulation,
} from "@angular/core";
import {
    combineLatest,
    distinctUntilChanged,
    filter,
    fromEvent,
    Observable,
    of,
    startWith,
    Subscription,
} from "rxjs";
import { map, switchMap, take } from "rxjs/operators";

import { LayoutFacade } from "@hermes/aphrodite/layout";
import { LOCALE, WINDOW } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { VTO } from "@hermes/states/add-to-cart";
import { ModalData, ModalFacade, ModalType } from "@hermes/states/modal";
import { AnalyticsService } from "@hermes/utils/analytics";
import { RouterService } from "@hermes/utils-generic/services/router";
import { BreakpointService } from "@hermes/utils-generic/services/user-interface";

import {
    CLICK_NOTIF_CLOSE_CART,
    CLICK_NOTIF_VIEW_CART,
} from "../../constants/modal.constants";
import { ProductInfoNotifClickEvent } from "../../events/product-info-notif-click.event";

@Component({
    selector: "h-modal-add-to-cart",
    templateUrl: "./modal-add-to-cart.component.html",
    styleUrls: ["./modal-add-to-cart.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ModalAddToCartComponent implements OnDestroy, AfterViewInit {
    @Input({ required: true })
    public modalData!: Extract<ModalData, { type: ModalType.AddToCart }>;
    public scroll$: Observable<number> = of(this.window).pipe(
        filter(Boolean),
        switchMap((win) =>
            fromEvent(win, "scroll").pipe(
                map(() => this.window?.scrollY),
                startWith(0),
                distinctUntilChanged(),
            ),
        ),
    );
    public topOffset$: Observable<number | undefined> = combineLatest([
        this.layoutFacade.headerAndBannerHeight$,
        this.scroll$,
    ]).pipe(
        map(([headerHeight]) =>
            this.breakpointService.mediumBreakpointMatcher()
                ? headerHeight
                : undefined,
        ),
    );

    private subscription: Subscription = new Subscription();

    constructor(
        private analyticsService: AnalyticsService,
        private layoutFacade: LayoutFacade,
        private modalFacade: ModalFacade,
        private breakpointService: BreakpointService,
        private routerService: RouterService,
        private ngZone: NgZone,
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window: Window,
        @Inject(LOCALE) private locale: Locale,
    ) {}

    public ngAfterViewInit(): void {
        // prevent hard scroll to modal caused by focus on close button in desktop
        this.subscription.add(
            this.ngZone.onStable
                .pipe(
                    take(1),
                    switchMap(() =>
                        this.breakpointService.smallestBreakpointObserver(),
                    ),
                )
                .subscribe((state: BreakpointState) => {
                    if (
                        state.matches &&
                        !this.modalData.disableScrollOnClosingModal
                    ) {
                        this.ngZone.runOutsideAngular(() => {
                            this.window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                            });
                        });
                    }
                }),
        );
    }

    // remove self from modal service when directive is destroyed
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public goToCart(): void {
        this.modalFacade.closeModal(true);
        this.analyticsService.sendData(
            new ProductInfoNotifClickEvent({
                context: this.modalData.context,
                eventType: CLICK_NOTIF_VIEW_CART,
                templateType: this.modalData.templateType,
            }),
        );
        // Changed to route with or without SPA depending on feature flag activation
        this.routerService.spaRedirect(`${this.locale.urlPrefix}/cart/`);
    }

    /**
     * Focus Add to Cart Button when the modal is close
     */
    public focusToAddToCart(): void {
        const addToCartButton =
            this.modalData.context === VTO
                ? this.document.querySelector<HTMLElement>(
                      "h-virtual-try-on-add-to-cart [name='add-to-cart']",
                  )
                : this.document
                      .getElementsByName("add-to-cart")
                      .item(
                          this.document.getElementsByName("add-to-cart")
                              .length - 1,
                      );

        if (addToCartButton) {
            addToCartButton.focus();
        }

        // to make a focus without scrolling we have to save the coordinates of x and y
        const { scrollX: x, scrollY: y } = this.window;
        this.window.scrollTo(x, y);
    }

    public close(): void {
        this.focusToAddToCart();
        this.modalFacade.closeModal(false);
        if (this.modalData.isUAEvent) {
            this.analyticsService.sendData(
                new ProductInfoNotifClickEvent({
                    templateType: this.modalData.templateType,
                    eventType: CLICK_NOTIF_CLOSE_CART,
                }),
            );
        }
    }
}

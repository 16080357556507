<button
    class="button-base button-{{ appearance }} size-{{ size }} height-{{ isNextToField ? 'high' : 'normal' }}"
    [class.disabled]="disabled || loading"
    [type]="type"
    [attr.id]="id"
    [name]="name"
    [class.keyboard-navigation-button]="layoutFacade.keyboardNavigation$ | async"
    [attr.aria-haspopup]="trayTargetId ? 'dialog' : undefined"
    [attr.aria-expanded]="trayTargetId ? 'false' : undefined"
    [attr.aria-controls]="trayTargetId ? trayTargetId : undefined"
    [attr.aria-describedby]="describedBy ? describedBy : undefined"
    [attr.aria-labelledby]="labelledBy ? labelledBy : undefined"
    [attr.aria-label]="label ? label : undefined"
    [attr.aria-disabled]="disabled || loading"
    (click)="disabled || loading ? stopPropagation($event) : pressed.emit()"
    [attr.data-testid]="dataTestId"
    #button>
    <ng-template #loader>
        <h-loader-spinner></h-loader-spinner>
    </ng-template>

    <ng-template #content>
        <div [class.button-content-container]="enableSvgSizes">
            <span class="svg-left">
                <ng-content select="[svgLeft]"></ng-content>
            </span>
            <ng-content *ngIf="!loading"></ng-content>
            <span class="svg-right">
                <ng-content select="[svgRight]"></ng-content>
            </span>
        </div>
        <span [class.svg-center]="enableSvgSizes">
            <ng-content select="[svgCenter]"></ng-content>
        </span>
    </ng-template>

    <div *ngIf="loading; then loader; else content"></div>
</button>

import { DOCUMENT, Location } from "@angular/common";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import { forkJoin, Subscription } from "rxjs";
import { map, take } from "rxjs/operators";

import { Context, LOCALE, Settings, getBuildVersion } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { UserFacade } from "@hermes/states/user";
import {
    AnalyticsService,
    getAnalyticsDeviceType,
} from "@hermes/utils/analytics";

import { NotFoundPageViewEvent } from "../../events/not-found-page-view.event";

@Injectable()
export class NotFoundPageAnalyticsService implements OnDestroy {
    private subscription = new Subscription();
    constructor(
        private context: Context,
        private userFacade: UserFacade,
        private analyticsService: AnalyticsService,
        private settings: Settings,
        private location: Location,
        @Inject(DOCUMENT) private document: Document,
        @Inject(LOCALE) private locale: Locale,
    ) {}

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Send analytics data for store page
     */
    public sendNotFoundPageViewEvent(): void {
        if (!this.context.isInBrowserMode()) {
            return;
        }

        this.subscription.add(
            forkJoin([
                this.userFacade.userPrefix$.pipe(take(1)),
                this.userFacade.userType$.pipe(
                    take(1),
                    map((userType) => userType || "non-connected"),
                ),
            ]).subscribe(([clientgender, clienttype]) => {
                this.analyticsService.sendData(
                    new NotFoundPageViewEvent({
                        eventname: "pageview",
                        pagename: this.document.title,
                        buildversion: getBuildVersion(this.settings.envKey),
                        devicetype: getAnalyticsDeviceType(),
                        languagecode: this.locale.langCode,
                        countrycode: this.locale.countryCode,
                        clienttype,
                        clientgender: clientgender as string,
                        pageTranslate: this.location.path().replace(/^\/+/, ""), // url after baseHref/locale, and without first slash.
                    }),
                );
            }),
        );
    }
}

import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { AbstractProductVariant, Product } from "@hermes/api-model-product";

import {
    cleanProductState,
    defaultImageGalleryPosition,
    fetchCrossSellProduct,
    fetchProduct,
    goBack,
    openSelectorToggle,
    ProductVariantType,
    resetSizeSelectedVariants,
    resetVariant,
    resetVariantSelectedBoxType,
    selectVariant,
    sendPageviewAnalytics,
    showMoreVariant,
    toggleZoom,
    ToggleZoomEvent,
    updateProductHeadTag,
} from "../actions/product-page.actions";
import {
    selectAPWskus,
    selectCrossSell,
    selectCurrentProduct,
    selectCurrentProductReference,
    selectIsLoadingProduct,
    selectSelectedVariant,
    selectTemplateType,
    selectVariantChangeType,
    selectVariants,
    selectVariantSelectedBoxType,
    selectZoomStatus,
} from "../selectors/product.selectors";

import { SelectedVariantVariantsState } from "./../types/product-page.types";

@Injectable()
export class ProductPageFacade {
    public currentProduct$ = this.store.select(selectCurrentProduct);
    public aPWskus$ = this.store.select(selectAPWskus);
    public variantChangeType$ = this.store.select(selectVariantChangeType);
    public variants$ = this.store.select(selectVariants);
    public variantSelectedBoxType$ = this.store.select(
        selectVariantSelectedBoxType,
    );

    public currentProductReference$ = this.store.select(
        selectCurrentProductReference,
    );

    public isProductLoading$ = this.store.select(selectIsLoadingProduct);
    public crossSell$ = this.store.select(selectCrossSell);
    public zoomStatus$ = this.store.select(selectZoomStatus);
    public templateType$ = this.store.select(selectTemplateType);

    constructor(private store: Store) {}

    /**
     * Send openSelectorToggle action
     *
     * @param toggleKey key to map for analytics purpose
     */
    public openSelectorToggle(toggleKey: string): void {
        this.store.dispatch(openSelectorToggle({ toggleKey }));
    }

    public toggleZoom(data: ToggleZoomEvent): void {
        this.store.dispatch(toggleZoom(data));
    }

    public pageView(product: Product): void {
        this.store.dispatch(sendPageviewAnalytics({ product }));
    }

    public goBack(): void {
        this.store.dispatch(goBack());
    }

    public fetchCrossSellProduct(): void {
        this.store.dispatch(fetchCrossSellProduct());
    }

    public updateProductHeadTag(
        product: Product,
        productReference: string,
    ): void {
        this.store.dispatch(
            updateProductHeadTag({
                product,
                productReference,
            }),
        );
    }

    public selectVariant(
        variantType: ProductVariantType,
        variantItem: AbstractProductVariant,
        position: number = 0,
    ): void {
        this.store.dispatch(
            selectVariant({
                variantType,
                variantItem,
                position,
            }),
        );
    }

    public resetVariant(position: number): void {
        this.store.dispatch(resetVariant({ position }));
    }

    public selectedVariant = (
        position: number,
    ): Observable<SelectedVariantVariantsState> =>
        this.store.select(selectSelectedVariant(position));

    public resetSizeSelectedVariants(): void {
        this.store.dispatch(resetSizeSelectedVariants());
    }

    public showMoreVariant(data: {
        id: string;
        isExpanded: boolean;
        sku?: string;
    }): void {
        this.store.dispatch(showMoreVariant({ data }));
    }
    public fetchProduct(sku: string, isVariant: boolean): void {
        this.store.dispatch(fetchProduct({ sku, isVariant }));
    }
    public cleanProductState(): void {
        this.store.dispatch(cleanProductState());
    }

    public defaultImageGalleryPosition(): void {
        this.store.dispatch(defaultImageGalleryPosition());
    }

    public resetVariantSelectedBoxType(): void {
        this.store.dispatch(resetVariantSelectedBoxType());
    }
}
